import React from 'react';

import Header from '../modules/MenuBars/MenuBars.jsx';
import Footer from '../modules/Footer/Footer.jsx';
import RouteList from '../routes/RouteList.jsx';

//Public Routes
const MyRoutes = () => (
  <div>
    <Header />
    {RouteList}
    <Footer />
  </div>
);

export default MyRoutes;
