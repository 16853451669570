// import namor from 'namor';

// const range = (len) => {
//   const arr = [];
//   for (let i = 0; i < len; i++) {
//     arr.push(i);
//   }
//   return arr;
// };

export const newRepo = (id, user, reponame) => {
  // console.log('newRepo id = ', id);
  return {
    id: `repo-${id}`,
    user: `${user}`,
    reponames: [`${reponame}`],
  };
};
const CheckRepoNames = (array) => {
  // console.log('CheckRepoNames repos = ', array);
  var len = array.length;
  array.map((d) => {
    if (d.reponames.length > 1) {
      // console.log('d = ', d.reponames);
      d.reponames.map((r) => {
        // console.log('reponame = ', newRepo(r + len, d.user, r));
        array.push(newRepo(len, d.user, r));
        len++;
      });
      array = array.filter((item) => item.id !== d.id);
    }
  });
  // console.log('CheckRepoNames repos = ', array);
  return array;
};

export default function GetRepoData(...repos) {
  // console.log('GetRepoData, repos = ', repos);
  const array = CheckRepoNames(JSON.parse(repos));
  const makeDataLevel = () => {
    return array;
  };
  return makeDataLevel();
  // }
}
