import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import {
  UPDATE_FIELD_AUTH,
  LOGIN,
  LOGIN_PAGE_LOADED,
  LOGIN_PAGE_UNLOADED,
} from '../../state/constants/actionTypes.jsx';
import CustomInput from '../../modules/CustomInput/CustomInput.jsx';
import ListErrors from '../ListErrors.jsx';
import agent from '../../agentAxios.jsx';
import withRouter from '../../routes/withRouter.jsx';

import loginStyle from './loginStyle.jsx';

const mapStateToProps = (state) => ({
  ...state.auth,
  isLoggedIn: state.common.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeEmail: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
  onChangePassword: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),
  onSubmit: (email, password) =>
    dispatch({ type: LOGIN, payload: agent.Auth.login(email, password) }),
  onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
  onLoad: () => dispatch({ type: LOGIN_PAGE_LOADED }),
});

const Login = ({ classes, onSubmit, onUnload, onLoad, isLoggedIn, errors }) => {
  let navigate = useNavigate();

  useEffect(() => {
    onLoad();
    return () => {
      onUnload();
    };
  }, [onLoad, onUnload]);

  useEffect(() => {
    document.title = 'GitGenius - Login';
    if (localStorage.getItem('jwt')) {
      window.gtag('event', 'Page Read', {
        event_category: 'Login',
        event_label: 'User Logged In',
      });
      navigate('/stargazeroverview');
    }
  }, [isLoggedIn]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  return (
    <Container maxWidth="sm" sx={{ paddingTop: '80px', minHeight: '100vh' }}>
      <Helmet>
        <title>GitGenius - Login to your GitGenius account</title>
        <meta name="description" content="Login page" />
        <link rel="canonical" href="https://www.gitgenius.co/login" />
      </Helmet>
      <ListErrors errors={errors} />
      <Grid justifyContent="center">
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h1>Sign In</h1>
            <p className="text-xs-center">
              <Link to="/register">Need an account?</Link>
            </p>
          </Paper>
        </Grid>
        <Paper>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit(email, password);
              // console.log('e = ', e);
            }}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12} sx={{ maxWidth: 400 }}>
                <Paper className={classes.paper}>
                  <CustomInput
                    type="email"
                    labelText="Email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sx={{ maxWidth: 400 }}>
                <Paper className={classes.paper}>
                  <CustomInput
                    type="password"
                    labelText="Password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sx={{ maxWidth: 400 }}>
                <Paper className={classes.paper}>
                  <Link to="/passwordreset">Forgot your password?</Link>
                </Paper>
              </Grid>
              <Grid item xs={12} sx={{ maxWidth: 400 }}>
                <Paper className={classes.paper}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    // disabled={inProgress}
                  >
                    Sign in
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Container>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(loginStyle)(Login))
);
