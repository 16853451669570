import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
export const LoggedOutView = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorLookup, setAnchorLookup] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenLookup = (event) => {
    setAnchorLookup(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseLookup = () => {
    setAnchorLookup(null);
  };
  const openlookup = Boolean(anchorLookup);

  return (
    <div>
      <Box
        sx={{
          float: 'right',
          flexGrow: 1,
          display: { xs: 'flex', md: 'none' },
        }}
      >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          <MenuItem component={Link} onClick={handleCloseNavMenu} to="/about">
            About
          </MenuItem>
          <MenuItem
            component={Link}
            onClick={handleCloseNavMenu}
            to="/collections"
          >
            Collections
          </MenuItem>
          <Divider />
          <MenuItem
            component={Link}
            onClick={handleCloseNavMenu}
            to="/lookuprepo"
          >
            Lookup a Repo
          </MenuItem>
          <MenuItem
            component={Link}
            onClick={handleCloseNavMenu}
            to="/lookupuser"
          >
            Lookup a User
          </MenuItem>
          <Divider />
          <MenuItem component={Link} onClick={handleCloseNavMenu} to="/contact">
            Contact Us
          </MenuItem>
          <Divider />
          <MenuItem component={Link} onClick={handleCloseNavMenu} to="/login">
            Login
          </MenuItem>
          <MenuItem
            component={Link}
            onClick={handleCloseNavMenu}
            to="/register"
          >
            Sign Up
          </MenuItem>
        </Menu>
      </Box>
      <Box
        sx={{
          float: 'right',
          flexGrow: 1,
          display: { xs: 'none', md: 'flex' },
        }}
      >
        <Button
          component={Link}
          to="/about"
          sx={{ my: 2, color: 'white', display: 'block', marginRight: '10px' }}
        >
          About
        </Button>
        {/* <Button
          component={Link}
          to="/repos/kubernetes/kubernetes"
          sx={{ my: 2, color: 'white', display: 'block', marginRight: '10px' }}
        >
          Sample Repo
        </Button> */}
        <Button
          component={Link}
          to="/collections"
          sx={{ my: 2, color: 'white', display: 'block', marginRight: '10px' }}
        >
          Collections
        </Button>
        <Button
          id="lookup-menu"
          aria-controls={openlookup ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openlookup ? 'true' : undefined}
          onClick={handleOpenLookup}
          style={{ color: 'white', marginRight: '20px' }}
        >
          Tools {<ExpandMoreIcon />}
        </Button>
        <Menu
          id="lookup-menu"
          aria-labelledby="tools-positioned-button"
          anchorEl={anchorLookup}
          open={openlookup}
          onClose={handleCloseLookup}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Button
            component={Link}
            onClick={handleCloseLookup}
            to="/lookuprepo"
            sx={{ display: 'block' }}
          >
            Lookup Repo
          </Button>
          <Button
            component={Link}
            onClick={handleCloseLookup}
            to="/lookupuser"
            sx={{ display: 'block' }}
          >
            Lookup User
          </Button>
        </Menu>
        <Button
          component={Link}
          to="/contact"
          sx={{ my: 2, color: 'white', display: 'block' }}
        >
          Contact Us
        </Button>
        <Button
          component={Link}
          to="/login"
          sx={{ my: 2, color: 'white', display: 'block' }}
        >
          Login
        </Button>
        <Button
          component={Link}
          to="/register"
          sx={{ my: 2, color: 'white', display: 'block' }}
        >
          Sign Up
        </Button>
      </Box>
    </div>
  );
};
