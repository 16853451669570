import React from 'react';
import { Box, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import {
  format,
  // formatDistance,
  // formatDistanceToNow,
  formatDistanceToNowStrict,
  // formatRelative,
  // subDays,
  // fromUnixTime,
  parseISO,
} from 'date-fns';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  fontSize: '1em',
}));

export default function RepoDetailSubData(props) {
  if (!props.Data.esupdated_at) {
    return <div></div>;
  }
  return (
    <Paper style={{ padding: '15px' }}>
      <h3>Summary Information</h3>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6}>
              <Item style={{ color: '#FD704B' }}>
                Updated{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {formatDistanceToNowStrict(
                    parseISO(props.Data.esupdated_at),
                    'minute'
                  )}{' '}
                  ago
                </span>
              </Item>
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6}>
              <Tooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">Note:</Typography>
                    <Typography color="inherit paragraph={true}">
                      Data prior to this date are estimates.
                    </Typography>
                    <Typography color="inherit" paragraph={true}>
                      Stargazer LastSeen, and Subscriber data are esimates,
                      based on users already indexed.
                    </Typography>
                    <Typography color="inherit" paragraph={true}>
                      However, Stargazers FirstSeen are accurate (using GraphQl
                      API),
                    </Typography>
                  </React.Fragment>
                }
              >
                <Item>
                  Added to GitGenius on{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {format(parseISO(props.Data.escreated_at), 'PPP')}
                  </span>
                </Item>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6}>
              <Item>
                Created on{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {format(parseISO(props.Data.created_at), 'PPP')}
                </span>
              </Item>
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6}>
              <Item>
                Open Issues/Pull Requests:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {props.Data.open_issues_count.toLocaleString()}
                </span>
                {' (change '}
                {props.Data.open_issues_count -
                  props.Data.esopen_issues_count_prev}
                {')'}
              </Item>
            </Grid>
            <Grid item xs={12} md={6}>
              <Item>
                Number of forks:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {props.Data.forks_count.toLocaleString()}
                </span>
              </Item>
            </Grid>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6}>
              <Item>
                Total Stargazers:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {props.Data.stargazers_count.toLocaleString()}
                </span>
                {' (change '}
                {props.Data.stargazers_count -
                  props.Data.esstargazers_count_prev}
                {')'}
              </Item>
            </Grid>
            <Grid item xs={12} md={6}>
              <Item>
                Total Subscribers:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {props.Data.subscribers_count.toLocaleString()}
                </span>
                {' (change '}
                {props.Data.subscribers_count -
                  props.Data.essubscribers_count_prev}
                {')'}
              </Item>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

// function FormRow() {
//   return (
//     <React.Fragment>
//       <Grid item xs={4}>
//         <Item>{format(new Date(), "'Today is a' eeee")}</Item>
//       </Grid>
//       <Grid item xs={4}>
//         <Item>
//           {formatDistance(subDays(new Date(), 3), new Date(), {
//             addSuffix: true,
//           })}
//         </Item>
//       </Grid>
//       <Grid item xs={4}>
//         <Item>{formatRelative(subDays(new Date(), 3), new Date())}</Item>
//       </Grid>
//     </React.Fragment>
//   );
// }
