import React, { useState, useEffect } from 'react';

const UpdateInterval = ({ setUpdateInterval }) => {
  const [seconds, setSeconds] = useState(0);
  const [counter, setCounter] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
      setUpdateInterval((updateinterval) => updateinterval + 1);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
      if (counter <= 1) {
        setCounter(10);
      }
    };
  }, [counter]);

  return (
    <div>
      <div>{seconds * 10} seconds have elapsed since mounting</div>
      <div>Refresh in {counter} seconds</div>
    </div>
  );
};

export default UpdateInterval;
