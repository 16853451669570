// Using https://react-table.tanstack.com/docs/faq and
// Code from https://thewidlarzgroup.com/react-table-7/
// eslint-disable-next-line max-len
// maybe this for axios call - https://stackoverflow.com/questions/54684255/how-do-you-make-axios-get-request-wait/54684366

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, LinearProgress } from '@mui/material';

import APIServerRoot from '../UIComponents/ApiEnvironment.jsx';

const RepoGetCounts = (props) => {
  const [starcount, setStarCount] = useState([]);
  const [gitstarcount, setGitStarCount] = useState([]);
  const [subcount, setSubCount] = useState([]);
  const [gitsubcount, setGitSubCount] = useState([]);

  const [isLoading, setLoading] = useState(true);

  var post_data = `[{"Name":"${props.reponame.toLowerCase()}"}]`;

  useEffect(() => {
    let API_ROOT = `${APIServerRoot()}/search/getrepostarcount`;
    let abortController = new AbortController();
    let isMounted = true; // note mutable flag
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_ROOT,
        data: { post_data },
      });
      if (isMounted) {
        setStarCount(axiosresponse.data);
        setLoading(false); //stop loading when data is fetched
      }
    };
    doFetch();
    // setLoading(false); //stop loading when data is fetched
    isMounted = true;
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    let API_ROOT = `${APIServerRoot()}/search/getreposubcount`;
    let abortController = new AbortController();
    let isMounted = true; // note mutable flag
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_ROOT,
        data: { post_data },
      });
      if (isMounted) {
        setSubCount(axiosresponse.data);
        setLoading(false); //stop loading when data is fetched
      }
    };
    doFetch();
    setLoading(false); //stop loading when data is fetched
    isMounted = true;
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    let abortController = new AbortController();
    let isMounted = true; // note mutable flag
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: `${APIServerRoot()}/search/getgithubrepo`,
        data: { post_data },
      });
      if (isMounted) {
        setGitStarCount(axiosresponse.data.stargazers_count);
        setGitSubCount(axiosresponse.data.subscribers_count);
      }
    };
    doFetch();
    setLoading(false); //stop loading when data is fetched
    isMounted = true;
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, []);

  return isLoading ? ( //Checkif if is loading
    <Box m={5} p={3}>
      <div style={{ textAlign: 'center' }}>
        <LinearProgress />
      </div>
    </Box>
  ) : (
    <Box m={1} p={1}>
      {/* <h5> */}
      GitGenius is currently tracking a total of:
      <ul>
        <li>
          {starcount.toLocaleString()} Stargazers, out of{' '}
          {gitstarcount.toLocaleString()}
        </li>
        <li>
          {subcount.toLocaleString()} Subscribers, out of{' '}
          {gitsubcount.toLocaleString()}
        </li>
      </ul>
      {/* </h5> */}
    </Box>
  );
};

export default RepoGetCounts;
