import React from 'react';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Alert from '@mui/material/Alert';

import GridContainer from '../../modules/Grid/GridContainer.jsx';
import GridItem from '../../modules/Grid/GridItem.jsx';
import CustomInput from '../../modules/CustomInput/CustomInput.jsx';
import Button from '../../modules/CustomButtons/Button.jsx';

import profileStyle from './profileStyle.jsx';

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
});

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      username: '',
      bio: '',
      email: '',
      password: '',
      verifypassword: '',
      gittoken: '',
      darkmode: '',
      repolist: '',
      member_status: '',
      preferences: '',
      saved: false,
      pwtest: false,
    };

    if (this.props.currentUser) {
      Object.assign(this.state, {
        firstname: this.props.currentUser.firstname,
        lastname: this.props.currentUser.lastname,
        username: this.props.currentUser.username,
        email: this.props.currentUser.email,
        gittoken: this.props.currentUser.gittoken,
        bio: this.props.currentUser.bio,
        darkmode: this.props.currentUser.darkmode,
        member_status: this.props.currentUser.member_status,
        preferences: this.props.currentUser.preferences,
        repolist: this.props.currentUser.Repolist,
      });
    }
    this.toggleSwitch = (field) => (ev) => {
      const state = this.state;
      this.state.darkmode = !this.state.darkmode;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
      window.localStorage.setItem('useDarkMode', this.state.darkmode);
    };

    this.updateState = (field) => (ev) => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };

    this.submitForm = (ev) => {
      let user = Object.assign({}, this.state);
      if (user.password != user.verifypassword) {
        ev.preventDefault();
        this.pwtest = true;
        alert('Please check password matches');
      } else {
        if (user.password.length == 0) {
          delete user.password;
        }
        delete user.verifypassword;
        this.setState(user);
        this.props.onSubmitForm(user);
        this.props.HandleFormChange(user);
        ev.preventDefault();
        this.saved = true;
      }
    };
  }

  createdOn = format(parseISO(this.props.currentUser.CreatedAt), 'MM/dd/yyyy');
  render() {
    return (
      <div className="root">
        <GridContainer>
          <GridItem>Created on {this.createdOn}</GridItem>
          <GridItem>Membership: {this.state.member_status}</GridItem>
        </GridContainer>
        <Divider style={{ paddingBottom: '50px' }} />
        <form onSubmit={this.submitForm}>
          <GridContainer justifyContent="center">
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                className="form-control"
                type="text"
                labelText="First Name"
                value={this.state.firstname}
                onChange={this.updateState('firstname')}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <CustomInput
                className="form-control"
                type="text"
                labelText="Last Name"
                value={this.state.lastname}
                onChange={this.updateState('lastname')}
              />
            </GridItem>
            <GridItem className="form-group">
              <CustomInput
                className="form-control form-control-lg"
                type="text"
                labelText="Username"
                // autoComplete="password"
                value={this.state.username}
                onChange={this.updateState('username')}
              />
            </GridItem>

            <GridItem className="form-group">
              <CustomInput
                className="form-control form-control-lg"
                type="text"
                multiline={true}
                rows={2}
                rowsMax={4}
                labelText="Bio"
                placeholder="Short bio about you"
                value={this.state.bio}
                onChange={this.updateState('bio')}
              />
            </GridItem>
            <GridItem className="form-group">
              <CustomInput
                className="form-control form-control-lg"
                type="email"
                labelText="Email"
                autoComplete="email"
                value={this.state.email}
                onChange={this.updateState('email')}
              />
            </GridItem>
            <GridItem className="form-group" xs={6}>
              <CustomInput
                className="form-control form-control-sm"
                type="password"
                // autoComplete="current-password"
                autoComplete="new-password"
                labelText="Password (Blank for no change)"
                // value={this.state.password}
                onChange={this.updateState('password')}
              />
            </GridItem>
            <GridItem className="form-group" xs={6}>
              <CustomInput
                className="form-control form-control-sm"
                type="password"
                labelText="Verify Password"
                autoComplete="new-password"
                // value={this.state.password}
                onChange={this.updateState('verifypassword')}
              />
            </GridItem>
            <GridItem className="form-group">
              {this.pwtest && (
                <Alert severity="error">Passwords do not match</Alert>
              )}{' '}
            </GridItem>
            <GridItem className="form-group">
              <CustomInput
                className="form-control form-control-lg"
                type="text"
                labelText="Github Token"
                value={this.state.gittoken}
                onChange={this.updateState('gittoken')}
              />
            </GridItem>
            <GridItem>
              <div>
                Light
                <Switch
                  checked={this.state.darkmode}
                  onChange={this.toggleSwitch(this.state.darkmode)}
                  color="primary"
                  name="checked"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                Dark
              </div>
            </GridItem>
            <GridItem style={{ paddingTop: '20px' }}>
              <FormControl onChange={this.updateState('numberofdays')}>
                <FormLabel id="days-radio-buttons-group-label">
                  Default Range of Graphs, Current value{' '}
                  {this.state.numberofdays}
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="days-radio-buttons-group-label"
                  defaultValue={30}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value={7}
                    control={<Radio />}
                    label="7 Days"
                  />
                  <FormControlLabel
                    value={30}
                    control={<Radio />}
                    label="30 days"
                  />
                  <FormControlLabel
                    value="90"
                    control={<Radio />}
                    label="90 days"
                  />
                  <FormControlLabel
                    value="180"
                    control={<Radio />}
                    label="180 days"
                  />
                  <FormControlLabel
                    value="365"
                    control={<Radio />}
                    label="1 Year"
                  />
                  <FormControlLabel
                    value="730"
                    control={<Radio />}
                    label="2 Years"
                  />
                </RadioGroup>
              </FormControl>
            </GridItem>
            <Button
              className="settingButton"
              color="primary"
              type="submit"
              disabled={this.state.inProgress}
            >
              Update Profile
            </Button>
            {this.saved && <Alert severity="success">Profile Saved</Alert>}
          </GridContainer>
        </form>
        <div style={{ minHeight: '50px' }}></div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withStyles(profileStyle)(ProfileForm));
