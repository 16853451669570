import * as React from 'react';
// import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Link } from 'react-router-dom';
import { ListItem, Tooltip } from '@mui/material';

export default function ToolsMenu(props) {
  const { handleDrawerClose } = props;

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
    handleDrawerClose;
  };

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, padding: 0 }}
      component="nav"
      aria-labelledby="submenu"
      // subheader={
      //   <ListSubheader component="div" id="nested-list-subheader">
      //     Nested List Items
      //   </ListSubheader>
      // }
    >
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <ManageSearchIcon />
        </ListItemIcon>
        <ListItemText primary="Tools" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            // button
            component={Link}
            key="lookupuser"
            to="/lookupuser"
            onClick={handleDrawerClose}
            sx={{ pl: 4 }}
          >
            <ListItemIcon>
              <Tooltip title="Lookup User">
                <ManageSearchIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Lookup User" />
          </ListItem>
          <ListItem
            component={Link}
            key="lookuprepo"
            to="/lookuprepo"
            onClick={handleDrawerClose}
            sx={{ pl: 4 }}
          >
            <ListItemIcon>
              <Tooltip title="Lookup Repository">
                <ManageSearchIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Lookup Repository" />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}
