import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid2,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const BColor = '#203a55'; // Background Color
const BTColor = '#48a7f2'; // Blue Text Color

const Item = styled(Paper)(() => ({
  textAlign: 'Left',
  width: '100%',
  backgroundColor: BColor,
  color: 'white',
}));

const MinH = 500;

import Background1 from '../../../assets/img/screenshots/1repodetails.webp';
import Background2 from '../../../assets/img/screenshots/2repodetailstimerange.webp';
import Background3 from '../../../assets/img/screenshots/3pickdayuserdetails.webp';
import Background4 from '../../../assets/img/screenshots/4starsoverview.webp';
import Background5 from '../../../assets/img/screenshots/5suboverview.webp';

// import Button from '../../modules/CustomButtons/Button';

const ProductDetails = () => {
  const [imageUrl, setImageUrl] = useState(Background1);

  const handleButtonClick1 = () => {
    setImageUrl(Background1);
  };

  const handleButtonClick2 = () => {
    setImageUrl(Background2);
  };

  const handleButtonClick3 = () => {
    setImageUrl(Background3);
  };
  const handleButtonClick4 = () => {
    setImageUrl(Background4);
  };
  const handleButtonClick5 = () => {
    setImageUrl(Background5);
  };
  return (
    <div>
      <Typography
        variant="h2"
        component="div"
        textAlign={'center'}
        lineHeight={'3em'}
        color={BTColor}
      >
        Features
      </Typography>
      <Grid2
        container
        spacing={1}
        columns={{ xs: 2, sm: 8, md: 12 }}
        sx={{ padding: '10px' }}
      >
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Stack>
            <Button
              onClick={handleButtonClick1}
              sx={{
                ':hover': {
                  backgroundColor: '#49a6f1',
                },
              }}
            >
              <Item>
                <h5 style={{ color: BTColor }}>Repository Details</h5>
                Detailed information about a repository - summary information
                (issues, pull requests, forks) and daily totals of user activity
                <p style={{ color: BTColor }}>Learn More</p>
              </Item>
            </Button>
            <Button
              onClick={handleButtonClick2}
              sx={{
                ':hover': {
                  backgroundColor: '#49a6f1',
                },
              }}
            >
              <Item>
                <h5 style={{ color: BTColor }}>Pick a Timeframe</h5>
                Detailed information on a specific repository, graphs of
                subscribers and stargazers, and even details of users
                <p style={{ color: BTColor }}>Learn More</p>
              </Item>
            </Button>
            <Button
              onClick={handleButtonClick3}
              sx={{
                ':hover': {
                  backgroundColor: '#49a6f1',
                },
              }}
            >
              <Item>
                <h5 style={{ color: BTColor }}>User details</h5>
                Select a day to show new users, and as well as those that
                unfollowed
                <p style={{ color: BTColor }}>Learn More &gt;</p>
              </Item>
            </Button>
            <Button
              onClick={handleButtonClick4}
              sx={{
                ':hover': {
                  backgroundColor: '#49a6f1',
                },
              }}
            >
              <Item>
                <h5 style={{ color: BTColor }}>Overview of New Stargazers</h5>
                Show Details of new Stargazers for all the Repositories you
                track
                <p style={{ color: BTColor }}>Learn More &gt;</p>
              </Item>
            </Button>
            <Button
              onClick={handleButtonClick5}
              sx={{
                ':hover': {
                  backgroundColor: '#49a6f1',
                },
              }}
            >
              <Item>
                <h5 style={{ color: BTColor }}>Overview of New Subscribers</h5>
                Show Details of new Subscribers for all the Repositories you
                track
                <p style={{ color: BTColor }}>Learn More &gt;</p>
              </Item>
            </Button>
          </Stack>
          {/* </Box> */}
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Link href="/repos/kubernetes/kubernetes">
            <Box
              elevation={0}
              sx={{
                minHeight: `${MinH}` + 'px',
                height: '100%',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundImage: `url(${imageUrl})`,
                // backgroundColor: 'BColor',
              }}
            ></Box>
          </Link>
          {/* <Popover props={imageUrl} /> */}
        </Grid2>
      </Grid2>
    </div>
  );
};

export default ProductDetails;
