/* eslint-disable react/jsx-key */
import React from 'react';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import { Table } from 'reactstrap';

import { Filter, DefaultColumnFilter } from './filters';

const TableContainer = ({ columns, data, darkmode }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn: { Filter: DefaultColumnFilter },
      },
      useFilters,
      useSortBy,
      usePagination
    );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  const modeSelector = () => {
    if (darkmode) {
      return true;
    } else {
      return false;
    }
  };

  // striped bordered hover variant="dark"
  // var args = `boardered dark`;
  // console.log('headerGroups1= ');
  // const { hkey, ...getHeaderGroupsProps } = headerGroups.getHeaderGroupProps();
  // console.log('hkey= ', hkey);

  return (
    <Table
      bordered
      striped
      responsive
      dark={modeSelector()}
      {...getTableProps()}
    >
      <thead style={{ fontSize: '1.2em' }}>
        {headerGroups.map((headerGroup) => {
          const { key, ...restHeaderProps } = headerGroup.getHeaderGroupProps();
          return (
            <tr key={key} {...restHeaderProps}>
              {headerGroup.headers.map((column) => {
                const { key, ...restColumnProps } = column.getHeaderProps();
                return (
                  <th
                    key={key}
                    {...restColumnProps}
                    style={{ textDecoration: 'underline' }}
                  >
                    <div {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const { key, ...restRowsProps } = row.getRowProps();

          return (
            <tr key={key} {...restRowsProps}>
              {row.cells.map((cell) => {
                const { key, ...restCellProps } = cell.getCellProps();
                return (
                  <td key={key} {...restCellProps}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TableContainer;
