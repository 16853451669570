import React from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import Paper from '@mui/material/Paper';
import {
  // AreaChart,
  BarChart,
  LineChart,
  XAxis,
  YAxis,
  Bar,
  Line,
  Tooltip,
  ReferenceLine,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// Hint here https://recharts.org/en-US/examples/CustomContentOfTooltip
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length >= 2) {
    // console.log('CustomTooltip payload is ', payload[0].payload);
    let date = payload[0].payload.D;
    return (
      <div
        style={{
          fontSize: '1em',
          justifyContent: 'center',
        }}
      >
        <table
          style={{
            minWidth: '100px',
            margin: '10px',
            color: 'white',
          }}
        >
          <thead>
            <tr>
              <th>{`${date}`}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> {`Total: ${payload[0].payload.T.toLocaleString()}  `}</td>
            </tr>
            <tr>
              <td> {`First Seen: ${payload[0].payload.F}  `}</td>
            </tr>
            <tr>
              <td> {`Last Seen: ${payload[1].value}  `}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

const CustomTooltipTotal = ({ active, payload }) => {
  // console.log('CustomTooltipTotal payload = ', payload);
  if (active && payload && payload.length >= 1) {
    // console.log('CustomTooltipTotal payload = ', payload);
    let date = payload[0].payload.D;
    return (
      <div
        style={{
          fontSize: '1em',
          justifyContent: 'center',
        }}
      >
        <table
          style={{
            minWidth: '100px',
            margin: '10px',
            color: 'white',
          }}
        >
          <thead>
            <tr>
              <th>{`${date}`}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> {`Total: ${payload[0].payload.T}  `}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

const renderLegend = () => {
  return (
    <div style={{ textAlign: 'center', marginBottom: '10px' }}>
      <span style={{ marginRight: '50px' }}>
        <BarChartIcon /> Total
      </span>
      <span style={{ color: '#48A7F2', marginRight: '50px' }}>
        <BarChartIcon /> First Seen
      </span>
      <span style={{ color: '#FD704B' }}>
        <BarChartIcon />
        Last Seen
      </span>
    </div>
  );
};

const renderLegendTotal = () => {
  return (
    <div style={{ textAlign: 'center', marginBottom: '10px' }}>
      <span style={{ color: '#48A7F2', marginRight: '50px' }}>
        <BarChartIcon /> Total
      </span>
    </div>
  );
};

export function ReChart(props) {
  // console.log('props = ', props);
  return (
    <Paper>
      <div style={{ width: '100%', height: props.Height }}>
        <ResponsiveContainer>
          <BarChart
            data={props.SeenData}
            stackOffset="sign"
            margin={{ top: 5, right: 10, left: 30, bottom: 5 }}
            cursor="pointer"
            syncId="syncID"
            onClick={(data, e) => {
              if (data != null) {
                props.setDetailDate(data.activePayload[0].payload);
              }
              if (e.nativeEvent.ctrlKey) {
                // eslint-disable-next-line no-console
                console.log('Ctrl+click has just happened!');
              }
              if (e.shiftKey) {
                // eslint-disable-next-line no-console
                console.log('Shift+click has just happened!');
              }
            }}
          >
            <XAxis dataKey="name" />
            <YAxis
              type="number"
              width={30}
              tick={{ fill: props.tickColor }}
              domain={[props.AxisL, props.AxisF, props.AxisT]}
              // domain={[
              //   (dataMin) => 0 - Math.abs(dataMin),
              //   (dataMax) => dataMax * 2,
              // ]}
              // domain={[-30, 50]}
              // domain={('auto', 'auto')}
              allowDataOverflow={true}
              // domain={['dataMin + 200', 'dataMax']}
              // https://stackoverflow.com/questions/50078787/recharts-set-y-axis-range
            />
            <Tooltip
              wrapperStyle={{ backgroundColor: '#37373D', padding: '5' }}
              labelStyle={{ color: 'green' }}
              itemStyle={{ color: 'cyan' }}
              content={<CustomTooltip />}
            />
            <Legend content={renderLegend} />
            <ReferenceLine y={0} strokeWidth={4} stroke="#82ca9d" />
            <Bar dataKey="F" stackId="A" fill="#48A7F2" />
            <Bar dataKey="L" stackId="A" fill="#FD704B" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
}

export function ReChartTotals(props) {
  // console.log('props = ', props);
  return (
    <Paper>
      <div style={{ width: '100%', height: props.Height }}>
        <ResponsiveContainer>
          <LineChart
            data={props.SeenData}
            stackOffset="sign"
            // margin="0 auto"
            margin={{ top: 5, right: 10, left: 30, bottom: 5 }}
            cursor="pointer"
            syncId="syncID"
            onClick={(data) => {
              if (data != null) {
                props.setDetailDate(data.activePayload[0].payload);
              }
            }}
          >
            <XAxis dataKey="name" />
            <YAxis
              type="number"
              width={30}
              tick={{ fill: props.tickColor }}
              domain={([min, dataMax]) => {
                if (props.SeenData) {
                  var l = props.SeenData.length;
                  min = props.SeenData[0].T;
                  for (var i = 1; i < l; i++) {
                    if (props.SeenData[i].T < min) {
                      min = props.SeenData[i].T;
                    }
                  }
                  return [min, dataMax];
                } else {
                  return [0, 0];
                }
              }}
              allowDataOverflow={true}
            />
            <Tooltip
              wrapperStyle={{ backgroundColor: '#37373D', padding: '5' }}
              labelStyle={{ color: 'green' }}
              itemStyle={{ color: 'cyan' }}
              content={<CustomTooltipTotal />}
            />
            <Legend content={renderLegendTotal} />
            <ReferenceLine y={0} stroke="#000" />
            <Line dataKey="T" stackId="A" fill="#48A7F2" />
            {/* <Bar dataKey="L" stackId="A" fill="#FD704B" /> */}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
}
