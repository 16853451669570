import * as React from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { addDays } from 'date-fns';

var options = [
  '# of Days',
  '1 Week',
  '1 Month',
  '3 Months',
  '6 Months',
  '1 Year',
  '2 Years',
  'Since creation',
];

export default function NumberOfDaysPicker(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  //   console.log('daysTotal = ', props.daysTotal);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  var lockedItem = `${props.daysTotal} days`;
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    var days = -props.daysTotal;
    if (index == 1) {
      days = -7;
    } else if (index == 2) {
      days = -30;
    } else if (index == 3) {
      days = -90;
    } else if (index == 4) {
      days = -180;
    } else if (index == 5) {
      days = -365;
    } else if (index == 6) {
      days = -730;
    } else if (index == 7) {
      days = -1 * props.daysFromCreate;
    }
    props.setEndDate(addDays(props.startDate, days));

    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ m: 0, p: 0 }}>
      <Button
        variant="outlined"
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickListItem}
        sx={{
          minHeight: 56,
        }}
      >
        {lockedItem}
      </Button>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            disabled={index === 0}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
