import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  LOGIN,
  LOGIN_PAGE_LOADED,
  LOGIN_PAGE_UNLOADED,
  REGISTER,
  REGISTER_PAGE_LOADED,
  REGISTER_PAGE_UNLOADED,
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  PROFILE_SAVED,
  PROFILE_PAGE_LOADED,
  PROFILE_PAGE_UNLOADED,
  STAROVERVIEW_PAGE_LOADED,
  STAROVERVIEW_PAGE_UNLOADED,
  SUBOVERVIEW_PAGE_LOADED,
  SUBOVERVIEW_PAGE_UNLOADED,
  REPOFOLLOWERS_PAGE_LOADED,
  REPOFOLLOWERS_PAGE_UNLOADED,
  REPOSUBSCRIBERS_PAGE_LOADED,
  REPOSUBSCRIBERS_PAGE_UNLOADED,
  REPOSETTINGS_SAVED,
  REPOSETTINGS_PAGE_LOADED,
  REPOSETTINGS_PAGE_UNLOADED,
  REPODETAILS_PAGE_LOADED,
  REPODETAILS_PAGE_UNLOADED,
  TOKENVERIFY_PAGE_LOADED,
  TOKENVERIFY,
  RESETPASSWORD_PAGE_SAVED,
  RESETPASSWORD_PAGE_LOADED,
  TERMSOFSERVICE_PAGE_LOADED,
  CONTACTUS_PAGE_LOADED,
  COOKIEPOLICY_PAGE_LOADED,
  PRIVACY_PAGE_LOADED,
  LOOKUPREPO_PAGE_LOADED,
  LOOKUPUSER_PAGE_LOADED,
  LOOKUPREPO_PAGE_UNLOADED,
  LOOKUPUSER_PAGE_UNLOADED,
  COLECTIONSHOME_PAGE_LOADED,
  COLECTIONK8S_PAGE_LOADED,
  COLECTIONSAI_PAGE_LOADED,
  COLECTIONSGITAC_PAGE_LOADED,
  COLECTIONSJSF_PAGE_LOADED,
  COLECTIONSDB_PAGE_LOADED,
} from '../constants/actionTypes.jsx';
const defaultState = {
  appName: 'GitGenius',
  token: null,
  showDrawer: false,
  isLoggedIn: false,
  viewChangeCounter: 0,
  currentUser: null,
  // useDarkMode: true,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        isLoggedIn: action.token ? true : false,
        token: action.token || null,
        appLoaded: true,
        useDarkMode: action.payload ? action.payload.user.darkmode : null,
        currentUser: action.payload ? action.payload.user : null,
      };
    case REDIRECT:
      // console.log('Here:', state);
      return { ...state, redirectTo: '/stargazeroverview' };
    case TOKENVERIFY:
      return {
        ...state,
        isLoggedIn: action.token ? true : false,
        currentUser: action.payload ? action.payload.user : null,
        token: action.token || null,
        redirectTo: '/resetpassword',
      };
    case LOGOUT:
      return {
        ...state,
        viewChangeCounter: state.viewChangeCounter + 1,
        redirectTo: action.error ? null : '/',
        token: null,
        currentUser: null,
        isLoggedIn: false,
      };
    case REPOSETTINGS_SAVED:
      return {
        ...state,
        viewChangeCounter: state.viewChangeCounter + 1,
        currentUser: action.error ? null : state.currentUser,
      };
    case PROFILE_SAVED:
      return {
        ...state,
        viewChangeCounter: state.viewChangeCounter + 1,
        currentUser: action.error ? null : state.currentUser,
        useDarkMode: state.currentUser.darkmode,
      };
    case RESETPASSWORD_PAGE_SAVED:
      return {
        ...state,
        viewChangeCounter: state.viewChangeCounter + 1,
        currentUser: action.error ? null : action.payload.user,
        useDarkMode: action.payload.user.darkmode,
        token: action.error ? null : action.payload.user.token,
        isLoggedIn: action.error ? false : true,
      };
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        token: action.error ? null : action.payload.user.token,
        isLoggedIn: action.error ? false : true,
        currentUser: action.error ? null : action.payload.user,
        viewChangeCounter: state.viewChangeCounter + 1,
        redirectTo: action.error ? null : '/stargazeroverview',
      };
    case HOME_PAGE_LOADED:
    case LOGIN_PAGE_LOADED:
    case TOKENVERIFY_PAGE_LOADED:
    case REGISTER_PAGE_LOADED:
    case RESETPASSWORD_PAGE_LOADED:
    case TERMSOFSERVICE_PAGE_LOADED:
    case CONTACTUS_PAGE_LOADED:
    case COOKIEPOLICY_PAGE_LOADED:
    case PRIVACY_PAGE_LOADED:
    case COLECTIONSHOME_PAGE_LOADED:
    case COLECTIONK8S_PAGE_LOADED:
    case COLECTIONSAI_PAGE_LOADED:
    case COLECTIONSGITAC_PAGE_LOADED:
    case COLECTIONSJSF_PAGE_LOADED:
    case COLECTIONSDB_PAGE_LOADED:
      // console.log('here in Home Loaded');
      return {
        ...state,
        viewChangeCounter: state.viewChangeCounter + 1,
        showDrawer: false,
      };
    case PROFILE_PAGE_LOADED:
    case REPOSETTINGS_PAGE_LOADED:
    case STAROVERVIEW_PAGE_LOADED:
    case SUBOVERVIEW_PAGE_LOADED:
    case REPOFOLLOWERS_PAGE_LOADED:
    case REPOSUBSCRIBERS_PAGE_LOADED:
    case REPODETAILS_PAGE_LOADED:
      // console.log('LoggedIN state = ', state);
      return {
        ...state,
        viewChangeCounter: state.viewChangeCounter + 1,
        showDrawer: true,
      };
    case LOOKUPREPO_PAGE_LOADED:
    case LOOKUPUSER_PAGE_LOADED:
      return {
        ...state,
        viewChangeCounter: state.viewChangeCounter + 1,
        showDrawer: true,
      };

    case HOME_PAGE_UNLOADED:
    case PROFILE_PAGE_UNLOADED:
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
    case STAROVERVIEW_PAGE_UNLOADED:
    case SUBOVERVIEW_PAGE_UNLOADED:
    case REPOFOLLOWERS_PAGE_UNLOADED:
    case REPOSUBSCRIBERS_PAGE_UNLOADED:
    case REPOSETTINGS_PAGE_UNLOADED:
    case REPODETAILS_PAGE_UNLOADED:
    case LOOKUPREPO_PAGE_UNLOADED:
    case LOOKUPUSER_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
    default:
      return state;
  }
};
