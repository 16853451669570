import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';

import PolicyPageStyle from '../Policies/PolicyPageStyle.jsx';
import {
  LOOKUPUSER_PAGE_LOADED,
  LOOKUPUSER_PAGE_UNLOADED,
} from '../../../state/constants/actionTypes.jsx';

import UserCheckForm from './UserCheckForm.jsx';
import UserGetDetails from './UserGetDetails.jsx';

const mapStateToProps = (state) => ({
  showaDrawer: state.common.showaDrawer,
  useDarkMode: false,
});
const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: LOOKUPUSER_PAGE_LOADED }),
  onUnload: () => dispatch({ type: LOOKUPUSER_PAGE_UNLOADED }),
});

function LookupUser(props) {
  const { classes } = props;
  let [userdata, setUserData] = useState('');

  function handleUserData(userdata) {
    setUserData(userdata);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    props.onLoad();
    window.gtag('event', 'Page Read', {
      event_category: 'Cookie Policy',
      event_label: 'Cookie Policy',
    });

    return () => {
      props.onUnload();
    };
  }, []);

  document.title = 'GitGenius - Lookup User';

  return (
    <Container
      sx={{
        paddingTop: '80px',
        minHeight: '100vh',
        paddingLeft: { lg: '340px' },
      }}
    >
      <Helmet>
        <title>GitGenius - User Lookup</title>
        <meta
          name="description"
          content="Look up a user, confirm it exists, and it has been added to GitGenius"
        />
        <link rel="canonical" href="https://www.gitgenius.co/lookupuser" />
      </Helmet>
      <div className={classes.toolbar} />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <h1 className="western">Lookup a User</h1>
        <h6>
          Look up a user, confirm it exists, and it has been added to GitGenius
        </h6>
        <UserCheckForm handleUserData={handleUserData} />
        <UserGetDetails userdata={userdata} />
      </Grid>
    </Container>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(PolicyPageStyle)(LookupUser));
