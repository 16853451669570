import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';

import ListErrors from '../ListErrors.jsx';
import agent from '../../agentAxios.jsx';
import withRouter from '../../routes/withRouter.jsx';
import {
  PROFILE_SAVED,
  PROFILE_PAGE_UNLOADED,
  PROFILE_PAGE_LOADED,
} from '../../state/constants/actionTypes.jsx';

import profileStyle from './profileStyle.jsx';
import ProfileForm from './Profileform.jsx';

const mapDispatchToProps = (dispatch) => ({
  // onChangeFirstname: (value) =>
  //   dispatch({ type: UPDATE_FIELD_AUTH, key: 'firstname', value }),
  onSubmitForm: (value) => {
    dispatch({ type: PROFILE_SAVED, payload: agent.Auth.save(value) });
  },
  onUnload: () => dispatch({ type: PROFILE_PAGE_UNLOADED }),
  onLoad: () => dispatch({ type: PROFILE_PAGE_LOADED }),
});

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  usedarkMode: state.common.useDarkMode,
  isLoggedIn: state.common.isLoggedIn,
});
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: this.props.currentUser,
    };
    this.HandleFormChange = this.HandleFormChange.bind(this);
  }
  componentDidMount() {
    this.props.onLoad();
  }

  HandleFormChange(newUser) {
    if (newUser) {
      this.props.currentUser.firstname = newUser.firstname;
      this.props.currentUser.lastname = newUser.lastname;
      this.props.currentUser.username = newUser.username;
      this.props.currentUser.bio = newUser.bio;
      this.props.currentUser.email = newUser.email;
      this.props.currentUser.gittoken = newUser.gittoken;
      this.props.currentUser.darkmode = newUser.darkmode;
      this.props.currentUser.member_status = newUser.member_status;
      this.props.currentUser.preferences = newUser.preferences;

      this.setState({ currentUser: newUser });
    }
  }

  render() {
    if (!this.props.currentUser) {
      return <div>Loading...</div>;
    }
    document.title = 'GitGenius - Profile';

    return (
      <Container
        sx={{
          paddingLeft: { lg: '340px' },
          paddingTop: '80px',
          minHeight: '100vh',
        }}
      >
        <div className="row justify-content-center align-self-center">
          <div className="col-sm-12 col-md-8">
            <h1 className="text-xs-center">Profile</h1>
            <ListErrors errors={this.props.errors} />
            <ProfileForm
              currentUser={this.props.currentUser}
              onSubmitForm={this.props.onSubmitForm}
              HandleFormChange={this.HandleFormChange}
            />
          </div>
        </div>
      </Container>
    );
  }
}

Profile.propTypes = {
  isLoggedIn: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  currentUser: PropTypes.object,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(profileStyle)(Profile))
);
