import React from 'react';
import { styled as muistyled } from '@mui/system';
import { Waves } from '@mui/icons-material';

const MuiHandleWrapper = muistyled('div')({
  height: '1rem',
  width: '2em',
  verticalAlign: 'top',
  float: 'left',
  marginRight: '0.5rem',
  svg: {
    width: '100%',
    height: '100%',
  },
  cursor: `${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')}`,
});

// const HandleWrapper = styled.div`
//   height: 1rem;
//   width: 2em;
//   vertical-align: top;
//   float: left;
//   // display: inline-block;
//   margin-right: 0.5rem;
//   svg {
//     width: 100%;
//     height: 100%;
//   }
//   cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
// `;

export const DragHandle = (props) => {
  // console.log('DragHandle props = ', props);

  return (
    <MuiHandleWrapper {...props}>
      <Waves />
    </MuiHandleWrapper>
  );
};
