import {
  REPOSETTINGS_SAVED,
  REPOSETTINGS_PAGE_UNLOADED,
  REPOSETTINGS_PAGE_LOADED,
  ASYNC_START,
} from '../constants/actionTypes.jsx';

export default (state = {}, action) => {
  switch (action.type) {
    case REPOSETTINGS_SAVED:
      // console.log('in reposetting reducer');
      return {
        ...state,
        inProgress: false,
        viewChangeCounter: state.viewChangeCounter + 1,
        errors: action.error ? action.payload.errors : null,
      };
    case REPOSETTINGS_PAGE_LOADED:
      // console.log('in reposettins reducer, REPOSETTINGS_PAGE_LOADED');

      return {};
    case REPOSETTINGS_PAGE_UNLOADED:
      return {};
    case ASYNC_START:
      return {
        ...state,
        inProgress: true,
        repoList: true,
      };
    default:
      return state;
  }
};
