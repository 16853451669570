import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { Helmet } from 'react-helmet-async';
import { Button, Container, Grid, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import withRouter from '../../routes/withRouter.jsx';
import {
  UPDATE_FIELD_AUTH,
  LOGINRESET,
  LOGINRESET_PAGE_LOADED,
  LOGINRESET_PAGE_UNLOADED,
} from '../../state/constants/actionTypes.jsx';
import ListErrors from '../ListErrors.jsx';
import CustomInput from '../../modules/CustomInput/CustomInput.jsx';
import Dialog from '../../modules/Dialog/MUiDialog.jsx';
import agent from '../../agentAxios.jsx';

import loginStyle from './loginStyle.jsx';

const mapStateToProps = (state) => ({
  ...state.auth,
  isLoggedIn: state.common.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeEmail: (value) =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
  onSubmit: (email) =>
    dispatch({
      type: LOGINRESET,
      payload: agent.Auth.loginreset(email),
    }),
  onUnload: () => dispatch({ type: LOGINRESET_PAGE_UNLOADED }),
  onLoad: () => dispatch({ type: LOGINRESET_PAGE_LOADED }),
});

const LoginReset = ({
  classes,
  email,
  errors,
  inProgress,
  onSubmit,
  onChangeEmail,
  onUnload,
  onLoad,
}) => {
  useEffect(() => {
    onLoad();
    return () => {
      onUnload();
    };
  }, [onLoad, onUnload]);

  useEffect(() => {
    document.title = 'GitGenius - Login Reset';
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const submitForm = (email) => (ev) => {
    ev.preventDefault();
    onSubmit(email);
    setIsOpen(!isOpen);
  };

  return (
    <Container maxWidth="sm" sx={{ paddingTop: '80px', minHeight: '100vh' }}>
      <Helmet>
        <title>GitGenius - Contact Us</title>
        <meta name="description" content="GitGenius Password Reset" />
        <link rel="canonical" href="https://www.gitgenius.co/passwordreset" />
      </Helmet>
      <div className={classes.container}>
        <ListErrors errors={errors} />
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <h1>Account Recovery</h1>
              <p className="text-xs-center">
                <Link to="/register">
                  Enter your email address to receive a recovery link...
                </Link>
              </p>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <form onSubmit={submitForm(email)}>
                <Grid container justifyContent="center">
                  <Grid item xs={12} sx={{ maxWidth: 400 }}>
                    <Paper className={classes.paper}>
                      <CustomInput
                        type="email"
                        labelText="Email"
                        autoComplete="email"
                        value={email}
                        onChange={(ev) => onChangeEmail(ev.target.value)}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sx={{ maxWidth: 400 }}>
                    <Paper className={classes.paper}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={inProgress}
                      >
                        Submit
                      </Button>
                      <Dialog
                        show={isOpen}
                        onClose={toggleModal}
                        title="Email Sent"
                        message=" If the email you entered is for a registered user, 
                        and email has been sent. Please check your email for a password reset link."
                      ></Dialog>
                    </Paper>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

LoginReset.propTypes = {
  isLoggedIn: PropTypes.bool,
  email: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.array,
  inProgress: PropTypes.bool,
  onSubmit: PropTypes.func,
  onChangeEmail: PropTypes.func,
  onUnload: PropTypes.func,
  onLoad: PropTypes.func,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(loginStyle)(LoginReset))
);
