import {
  LOGIN,
  PROFILE,
  REGISTER,
  LOGIN_PAGE_UNLOADED,
  TOKENVERIFY_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  ASYNC_START,
  UPDATE_FIELD_AUTH,
  UPDATE_PROFILE_FIELD,
} from '../constants/actionTypes.jsx';

export default (state = {}, action) => {
  switch (action.type) {
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.errors : null,
      };
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
    case TOKENVERIFY_PAGE_UNLOADED:
      return {};
    case ASYNC_START:
      // console.log('in Auth ASYNC_START action = ', action);
      // console.log('in Auth ASYNC_START state = ', state);

      if (
        action.subtype === LOGIN ||
        action.subtype === REGISTER ||
        action.subtype === PROFILE
      ) {
        // console.log('ASYNC_START 2nd action = ', action.value);
        return {
          ...state,
          inProgress: true,
        };
      }
      break;
    case UPDATE_FIELD_AUTH:
      return { ...state, [action.key]: action.value };
    case UPDATE_PROFILE_FIELD:
      // console.log('UPDATE_PROFILE_FIELD =', action);
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }

  return state;
};
