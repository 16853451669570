// Using https://react-table.tanstack.com/docs/faq and
// Code from https://thewidlarzgroup.com/react-table-7/
// eslint-disable-next-line max-len
// maybe this for axios call - https://stackoverflow.com/questions/54684255/how-do-you-make-axios-get-request-wait/54684366

import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import APIServerRoot from '../UIComponents/ApiEnvironment.jsx';
// import CircularProgress from '@mui/material/CircularProgress';
import LinearWithValueLabel from '../UIComponents/ProgressBar.jsx';

import TableContainer from './TableContainer.jsx';

const SubRepoTable = (props) => {
  const storeSubOverview = localStorage.getItem('suboverview');
  var storeSubTime = localStorage.getItem('subtiime');

  const [subdata, setSubData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  var API_ROOT = `${APIServerRoot()}/search/${props.List}`;

  const post_data = JSON.stringify(props.Repolist);

  const e = new Date();
  var HoursUTC = `Last ${e.getUTCHours()}h, ${e.getUTCMinutes()}m`;

  useEffect(() => {
    let abortController = new AbortController();
    let isMounted = true; // note mutable flag
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_ROOT,
        data: { post_data },
      });
      var repos = axiosresponse.data;
      localStorage.setItem('suboverview', JSON.stringify(repos));
      var object = { value: 'value', timestamp: new Date().getTime() };
      localStorage.setItem('subtiime', JSON.stringify(object));

      if (isMounted) {
        setLoading(false); //stop loading when data is fetched
        setSubData(repos);
      }
    };

    if (!storeSubTime) {
      var tmp = { value: 'value', timestamp: new Date().getTime() };
      localStorage.setItem('subtiime', JSON.stringify(tmp));
      storeSubTime = localStorage.getItem('subtiime');
    }
    var timestamp = JSON.parse(storeSubTime);
    const diff = (Date.now() - timestamp.timestamp) / 1000 / 60;

    if (!storeSubOverview || diff > 5) {
      var object = { value: 'value', timestamp: new Date().getTime() };
      localStorage.setItem('startiime', JSON.stringify(object));
      storeSubTime = localStorage.getItem('startiime');
      doFetch();
    } else {
      setSubData(JSON.parse(localStorage.getItem('suboverview')));
      setLoading(false); //stop loading when data is fetched
      isMounted = true;
    }
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Repository',
        accessor: 'Name',
        disableFilters: true,
        Cell: ({ cell: { value } }) => (
          <Link to={`/repos/${value}`}>{value}</Link>
        ),
      },
      {
        Header: HoursUTC,
        accessor: 'OneDay',
        disableFilters: true,
      },
      {
        Header: 'Last 7 Days',
        accessor: 'SevenDay',
        disableFilters: true,
      },
    ],
    []
  );
  return isLoading ? ( //Checkif if is loading
    <div>
      <h5>New Subscribers </h5>
      <div style={{ textAlign: 'center' }}>
        <p>Loading...</p>
        {/* <CircularProgress /> */}
        <LinearWithValueLabel interval={props.Repolist.length * 15} />
      </div>
    </div>
  ) : (
    <div>
      <h5>New Subscribers</h5>
      <TableContainer
        columns={columns}
        data={subdata}
        darkmode={props.Darkmode}
      />
    </div>
  );
};

export default SubRepoTable;
