export default function APIServerRoot() {
  // For local, home (staging) or production deployment
  if (process.env.REACT_APP_API_HOST === 'dev') {
    return 'http://localhost:3000/api';
  } else if (process.env.REACT_APP_API_HOST === 'home') {
    return 'http://192.168.1.216:3000/api';
  } else if (process.env.REACT_APP_API_HOST === 'prod') {
    return 'https://www.gitgenius.co/api';
  }
  return 'error';
}
