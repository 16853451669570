import agent from '../agentAxios.jsx';

import {
  ASYNC_START,
  ASYNC_END,
  LOGIN,
  LOGOUT,
  REGISTER,
  TOKENVERIFY,
} from './constants/actionTypes.jsx';

const promiseMiddleware = (store) => (next) => (action) => {
  if (isPromise(action.payload)) {
    store.dispatch({ type: ASYNC_START, subtype: action.type });

    const currentView = store.getState().viewChangeCounter;
    const skipTracking = action.skipTracking;
    // console.log('promiseMiddleware PAYLOAD', action);
    // console.log('in Middleware, store = ', store.getState());

    action.payload.then(
      (res) => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        /*eslint no-console: ["error", { allow: ["log"] }] */
        // console.log('RESULT', res);
        action.payload = res;
        store.dispatch({ type: ASYNC_END, promise: action.payload });
        store.dispatch(action);
      },
      (error) => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        if (error.message.includes('Request has been terminated')) {
          console.log(
            'Cannot connect to middleware (check api-server is running)'
          );
          return;
        }
        if (error.response) {
          action.payload = error.response.body;
        } else {
          action.payload = null;
        }
        if (!action.skipTracking) {
          store.dispatch({ type: ASYNC_END, promise: action.payload });
        }
        store.dispatch(action);
      }
    );

    return;
  }

  next(action);
};
/*eslint no-unused-vars: "off"*/
const localStorageMiddleware = () => (next) => (action) => {
  // console.log('In Middleware Action TYPE, ', action.type);
  // console.log('In Middleware Action Payload, ', action.payload);
  // console.log('In Middleware Action Error, ', action.error);
  if (action.type === REGISTER || action.type === LOGIN) {
    if (!action.payload.errors && !action.error) {
      window.localStorage.setItem('jwt', action.payload.user.token);
      window.localStorage.setItem('useDarkMode', action.payload.user.darkmode);
      agent.setToken(action.payload.user.token);
    } else {
      action.error = 'error';
    }
  } else if (action.type === TOKENVERIFY) {
    if (action.payload.user.email !== '') {
      window.localStorage.setItem('jwt', action.payload.user.token);
      window.localStorage.setItem('useDarkMode', action.payload.user.darkmode);
      agent.setToken(action.payload.user.token);
    }
  } else if (action.type === LOGOUT) {
    window.localStorage.setItem('jwt', '');
    window.localStorage.clear(); //for localStorage
    window.sessionStorage.clear(); //for sessionStorage
    agent.setToken(null);
    location.href = '/';
  }
  // else if (action.type === LOGINRESET) {
  //   alert('Message Sent');
  // }

  next(action);
};

function isPromise(v) {
  return v && typeof v.then === 'function';
}

export { promiseMiddleware, localStorageMiddleware };
