// JavaScript program to find variance and
// standard deviation of given array.
// Function for calculating
// variance

function VarianceMean(a, n, fOrl) {
  // Compute mean (average of elements)
  var sum = 0;
  var maxy = 0;
  for (var i = 0; i < n; i++) {
    if (fOrl == 'L') {
      sum += a[i].L;
      if (a[i].L < maxy) {
        maxy = a[i].L;
      }
    } else {
      sum += a[i].F;
      if (a[i].F > maxy) {
        maxy = a[i].F;
      }
    }
  }
  sum = sum - maxy;
  var mean = sum / (n - 1);
  var sqDiff = 0;
  for (var i2 = 0; i2 < n; i2++) {
    if (fOrl == 'L' && a[i2].L != maxy) {
      sqDiff += (a[i2].L - mean) * (a[i2].L - mean);
    }
    if (fOrl == 'F' && a[i2].F != maxy) {
      sqDiff += (a[i2].F - mean) * (a[i2].F - mean);
    }
  }
  var variance = sqDiff / n;
  return { variance, mean };
}

function Max(a, n, fOrl) {
  // Compute mean (average of elements)
  var maxy = 0;
  for (var i = 0; i < n; i++) {
    if (fOrl == 'L') {
      // console.log('(a[i].L = ', a[i].L);
      if (a[i].L < maxy) {
        maxy = a[i].L;
      }
    } else {
      // console.log('(a[i].F = ', a[i].F);
      if (a[i].F > maxy) {
        maxy = a[i].F;
      }
    }
  }
  return maxy;
}

// domainNegYAxis finds the top 10 elements, returns the least of those to set max axis
export function domainNegYAxis(seendata, normalize) {
  if (!seendata) {
    return 0;
  }
  if (normalize) {
    var n = seendata.length;
    // var seenvar = variance(seendata, n, 'L');
    var { variance, mean } = VarianceMean(seendata, n, 'L');
    // console.log('(domainNegYAxis) variance, mean = ', variance, mean);
    var stddev = Math.sqrt(variance) * -1;
    if (stddev == 0) {
      stddev = -1;
    }
    // console.log('(domainNegYAxis) stddev = ', Math.floor(stddev));
    var returnval = Math.floor(mean) + 2 * Math.floor(stddev);
    //    Math.ceil(mean / 10) * 10 + 2 * (Math.floor(stddev / 10) * 10);
    returnval = Math.floor(returnval);
    // console.log('(domainNegYAxis) returnval = ', returnval);
    return returnval;
  } else {
    returnval = Max(seendata, seendata.length, 'L');
    return returnval;
  }
}

export function domainPosYAxis(seendata, normalize) {
  // console.log('seendata = ', seendata);
  if (!seendata) {
    return 0;
  }
  if (normalize) {
    var n = seendata.length;
    if (seendata == 'nil') {
      return 0;
    }
    // var seenvar = variance(seendata, n, 'L');
    var { variance, mean } = VarianceMean(seendata, n, 'F');
    // console.log('(domainPosYAxis) variance, mean = ', variance, mean);
    var stddev = Math.sqrt(variance);
    if (stddev == 0) {
      stddev = 1;
    }
    // console.log('(domainPosYAxis) stddev = ', Math.ceil(stddev));
    var returnval = Math.ceil(mean) + 2 * Math.ceil(stddev);
    returnval = Math.round(returnval);
    // console.log('(domainPosYAxis) returnval: ', returnval);
    // var returnval = Math.round(stddev);
    return returnval; //stddev * 5;
  } else {
    returnval = Max(seendata, seendata.length, 'F');
    return returnval;
  }
}
