export const Repos = [
  {
    name: 'angular/angular',
  },
  {
    name: 'angular/angular.js',
  },
  {
    name: 'twbs/bootstrap',
  },
  {
    name: 'oven-sh/bun',
  },
  {
    name: 'facebook/create-react-app',
  },
  {
    name: 'denoland/deno',
  },
  {
    name: 'electron/electron',
  },
  {
    name: 'emotion-js/emotion',
  },
  {
    name: 'expressjs/express',
  },
  {
    name: 'jaredpalmer/formik',
  },
  {
    name: 'denoland/fresh',
  },
  {
    name: 'gatsbyjs/gatsby',
  },
  {
    name: 'gohugoio/hugo',
  },
  {
    name: 'vercel/hyper',
  },
  {
    name: 'ionic-team/ionic-framework',
  },
  {
    name: 'mobxjs/mobx',
  },
  {
    name: 'moby/moby',
  },
  {
    name: 'vercel/next.js',
  },
  {
    name: 'nuxt/nuxt',
  },
  {
    name: 'preactjs/preact',
  },
  {
    name: 'facebook/react',
  },
  {
    name: 'facebook/react-native',
  },
  {
    name: 'reduxjs/redux',
  },
  {
    name: 'reduxjs/redux-thunk',
  },
  {
    name: 'reduxjs/redux-toolkit',
  },
  {
    name: 'facebook/relay',
  },
  {
    name: 'pytorch/pytorch',
  },
  {
    name: 'storybookjs/storybook',
  },
  {
    name: 'trpc/trpc',
  },
  {
    name: 'microsoft/typescript',
  },
  {
    name: 'vuejs/vue',
  },
  {
    name: 'petyosi/react-virtuoso',
  },
];
