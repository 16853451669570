export const APP_LOAD = 'APP_LOAD';
export const DARK_MODE = 'DARK_MODE';
export const REDIRECT = 'REDIRECT';
export const REPOSETTINGS_SAVED = 'REPOSETTING_SAVED';
export const REPOSETTINGS = 'REPOSETTING';
export const REPOSETTINGS_PAGE_UNLOADED = 'REPOSETTING_PAGE_UNLOADED';
export const REPOSETTINGS_PAGE_LOADED = 'REPOSETTING_PAGE_LOADED';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const PROFILE_SAVED = 'PROFILE_SAVED';
export const PROFILE = 'PROFILE';
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGINRESET = 'LOGINRESET';
export const LOGIN_PAGE_LOADED = 'LOGIN_PAGE_LOADED';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const LOGINRESET_PAGE_LOADED = 'LOGINRESET_PAGE_LOADED';
export const LOGINRESET_PAGE_UNLOADED = 'LOGINRESET_PAGE_LOADED';
export const TOKENVERIFY = 'TOKENVERIFY';
export const TOKENVERIFY_PAGE_LOADED = 'TOKENVERIFY_PAGE_LOADED';
export const TOKENVERIFY_PAGE_UNLOADED = 'TOKENVERIFY_PAGE_LOADED';
export const RESETPASSWORD_PAGE_SAVED = 'RESETPASSWORD_PAGE_SAVED';
export const RESETPASSWORD_PAGE_LOADED = 'RESETPASSWORD_PAGE_LOADED';
export const RESETPASSWORD_PAGE_UNLOADED = 'RESETPASSWORD_PAGE_LOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const REGISTER_PAGE_LOADED = 'REGISTER_PAGE_LOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_PROFILE_FIELD = 'UPDATE_PROFILE_FIELD';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const STAROVERVIEW_PAGE_LOADED = 'STAROVERVIEW_PAGE_LOADED';
export const STAROVERVIEW_PAGE_UNLOADED = 'STAROVERVIEW_PAGE_UNLOADED';
export const SUBOVERVIEW_PAGE_LOADED = 'SUBOVERVIEW_PAGE_LOADED';
export const SUBOVERVIEW_PAGE_UNLOADED = 'SUBOVERVIEW_PAGE_UNLOADED';
export const REPOFOLLOWERS_PAGE_LOADED = 'FOLLOWERS_PAGE_LOADED';
export const REPOFOLLOWERS_PAGE_UNLOADED = 'FOLLOWERS_PAGE_UNLOADED';
export const REPOSUBSCRIBERS_PAGE_LOADED = 'REPOSUBSCRIBERS_PAGE_LOADED';
export const REPOSUBSCRIBERS_PAGE_UNLOADED = 'REPOSUBSCRIBERS_PAGE_UNLOADED';
export const USERDETAILS_PAGE_LOADED = 'USERDETAILS_PAGE_LOADED';
export const USERDETAILS_PAGE_UNLOADED = 'USERDETAILS_PAGE_UNLOADED';
export const REPODETAILS_PAGE_LOADED = 'REPODETAILS_PAGE_LOADED';
export const REPODETAILS_PAGE_UNLOADED = 'REPODETAILS_PAGE_UNLOADED';
export const PRIVACY_PAGE_LOADED = 'PRIVACY_PAGE_LOADED';
export const PRIVACY_PAGE_UNLOADED = 'PRIVACY_PAGE_UNLOADED';
export const TERMSOFSERVICE_PAGE_LOADED = 'TERMSOFSERVICE_PAGE_LOADED';
export const TERMSOFSERVICE_PAGE_UNLOADED = 'TERMSOFSERVICE_PAGE_UNLOADED';
export const COOKIEPOLICY_PAGE_LOADED = 'COOKIEPOLICY_PAGE_LOADED';
export const COOKIEPOLICY_PAGE_UNLOADED = 'COOKIEPOLICY_PAGE_UNLOADED';
export const CONTACTUS_PAGE_LOADED = 'CONTACTUS_PAGE_LOADED';
export const CONTACTUS_PAGE_UNLOADED = 'CONTACTUS_PAGE_UNLOADED';
export const EXAMPLE_REPODTAIL_LOADED = 'EXAMPLE_REPODTAIL_LOADED';
export const EXAMPLE_REPODTAIL_UNLOADED = 'EXAMPLE_REPODTAIL_UNLOADED';
export const NOTIFICATIONS_SAVED = 'NOTIFICATIONS_SAVED';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const NOTIFICATIONS_PAGE_LOADED = 'NOTIFICATIONS_PAGE_LOADED';
export const NOTIFICATIONS_PAGE_UNLOADED = 'NOTIFICATIONS_PAGE_UNLOADED';
export const ADMINMAIN = 'ADMINMAIN';
export const ADMINMAIN_PAGE_LOADED = 'ADMINMAIN_PAGE_LOADED';
export const ADMINMAIN_PAGE_UNLOADED = 'ADMINMAIN_PAGE_UNLOADED';
export const LOOKUPUSER_PAGE_LOADED = 'LOOKUPUSER_PAGE_LOADED';
export const LOOKUPUSER_PAGE_UNLOADED = 'LOOKUPUSER_PAGE_UNLOADED';
export const LOOKUPREPO_PAGE_LOADED = 'LOOKUPREPO_PAGE_LOADED';
export const LOOKUPREPO_PAGE_UNLOADED = 'LOOKUPREPO_PAGE_UNLOADED';
export const ABOUT_PAGE_LOADED = 'ABOUT_PAGE_LOADED';
export const ABOUT_PAGE_UNLOADED = 'ABOUT_PAGE_UNLOADED';
export const COLECTIONSHOME_PAGE_LOADED = 'COLECTIONSHOME_PAGE_LOADED';
export const COLECTIONSHOME_PAGE_UNLOADED = 'COLECTIONSHOME_PAGE_UNLOADED';
export const COLECTIONK8S_PAGE_LOADED = 'COLECTIONK8S_PAGE_LOADED';
export const COLECTIONK83_PAGE_UNLOADED = 'COLECTIONK83_PAGE_UNLOADED';
export const COLECTIONSAI_PAGE_LOADED = 'COLECTIONSAI_PAGE_LOADED';
export const COLECTIONSAI_PAGE_UNLOADED = 'COLECTIONSAI_PAGE_UNLOADED';
export const COLECTIONSGITAC_PAGE_LOADED = 'COLECTIONSGITAC_PAGE_LOADED';
export const COLECTIONSGITAC_PAGE_UNLOADED = 'COLECTIONSGITAC_PAGE_UNLOADED';
export const COLECTIONSJSF_PAGE_LOADED = 'COLECTIONSJSF_PAGE_LOADED';
export const COLECTIONSJSF_PAGE_UNLOADED = 'COLECTIONSJSF_PAGE_UNLOADED';
export const COLECTIONSCNT_PAGE_LOADED = 'COLECTIONSCNT_PAGE_LOADED';
export const COLECTIONSCNT_PAGE_UNLOADED = 'COLECTIONSCNT_PAGE_UNLOADED';
export const COLECTIONSDB_PAGE_LOADED = 'COLECTIONSDB_PAGE_LOADED';
export const COLECTIONSDB_PAGE_UNLOADED = 'COLECTIONSDB_PAGE_UNLOADED';
