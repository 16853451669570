// Using https://react-table.tanstack.com/docs/faq and
// Code from https://thewidlarzgroup.com/react-table-7/
// eslint-disable-next-line max-len
// maybe this for axios call - https://stackoverflow.com/questions/54684255/how-do-you-make-axios-get-request-wait/54684366

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, LinearProgress } from '@mui/material';
import { format } from 'date-fns';

import APIServerRoot from '../UIComponents/ApiEnvironment.jsx';
import { successBoxShadow } from '../../assets/jss/material-kit-pro-react.jsx';

const RepoGetDetails = (props) => {
  const [repodetaildata, setRepoDetailData] = useState([]); // for Repo Details information
  const [isLoading, setLoading] = useState(true);
  const [repoupdatedon, setRepoUpdatedOn] = useState('');
  const [starcount, setStarCount] = useState([]);
  const [subcount, setSubCount] = useState([]);

  var post_data = `[{"Name":"${props.reponame.toLowerCase()}"}]`;

  useEffect(() => {
    let API_ROOT = `${APIServerRoot()}/search/getrepostarcount`;
    let abortController = new AbortController();
    let isMounted = true; // note mutable flag
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_ROOT,
        data: { post_data },
      });
      if (isMounted) {
        setStarCount(axiosresponse.data);
      }
    };
    doFetch();
    // setLoading(false); //stop loading when data is fetched
    // isMounted = true;
    return () => {
      // isMounted = false;
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    let API_ROOT = `${APIServerRoot()}/search/getreposubcount`;
    let abortController = new AbortController();
    let isMounted = true; // note mutable flag
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_ROOT,
        data: { post_data },
      });
      if (isMounted) {
        setSubCount(axiosresponse.data);
      }
    };
    doFetch();
    // setLoading(false); //stop loading when data is fetched
    // isMounted = true;
    return () => {
      // isMounted = false;
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    let abortController = new AbortController();
    let API_DETAILS = `${APIServerRoot()}/search/getrepodetails`;
    var post_data = `[{"Name":"${props.reponame.toLowerCase()}"}]`;
    // console.log('post_data = ', post_data);
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
        data: { post_data },
      });
      setRepoDetailData(axiosresponse.data);
      var date = new Date(axiosresponse.data.esupdated_at);
      var formattedDate = format(date, 'MMMM do, yyyy H:mma');
      setRepoUpdatedOn(formattedDate);
      setLoading(false);
    };
    // setLoading(true);
    doFetch();
    return () => {
      // console.log('abort controller');
      abortController.abort();
    };
  }, []);

  return isLoading ? ( //Checkif if is loading
    <Box m={5} p={3}>
      <div style={{ textAlign: 'center' }}>
        <LinearProgress />
      </div>
    </Box>
  ) : (
    <Box m={0} p={1} sx={{ color: successBoxShadow }}>
      <ul>
        <li>Last updated at {repoupdatedon}</li>
        <li>
          Total users:
          <ul>
            <li>
              {starcount.toLocaleString()} Stargazers, with{' '}
              {repodetaildata.stargazers_count.toLocaleString()} currently
              Starred
            </li>
            <li>
              {subcount.toLocaleString()} Subscribers, with{' '}
              {repodetaildata.subscribers_count.toLocaleString()} currently
              Subscribed
            </li>
          </ul>
        </li>
        <li>{repodetaildata.open_issues_count.toLocaleString()} Open Issues</li>
        <li>{repodetaildata.forks_count.toLocaleString()} Forks</li>
      </ul>
    </Box>
  );
};

export default RepoGetDetails;
