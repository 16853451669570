import * as React from 'react';
// import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import { ListItem, Tooltip } from '@mui/material';

export default function SettingMenu(props) {
  const { handleDrawerClose } = props;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
    handleDrawerClose;
  };

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, padding: 0 }}
      component="nav"
      aria-labelledby="submenu"
      // subheader={
      //   <ListSubheader component="div" id="nested-list-subheader">
      //     Nested List Items
      //   </ListSubheader>
      // }
    >
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            // button
            component={Link}
            key="notifications"
            to="/notifications"
            onClick={handleDrawerClose}
            sx={{ pl: 4 }}
          >
            <ListItemIcon>
              <Tooltip title="Notification settings">
                <SettingsIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Notification settings" />
          </ListItem>
          <ListItem
            component={Link}
            key="profile"
            to="/profile"
            onClick={handleDrawerClose}
            sx={{ pl: 4 }}
          >
            <ListItemIcon>
              <Tooltip title="Profile settings">
                <SettingsIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Profile settings" />
          </ListItem>
          <ListItem
            component={Link}
            key="RepoSettings"
            to="/reposettings"
            onClick={handleDrawerClose}
            sx={{ pl: 4 }}
          >
            <ListItemIcon>
              <Tooltip title="Repo settings">
                <SettingsIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Repo settings" />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}
