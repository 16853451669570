export default function CreateUniqueKey(array) {
  var highest = 0;
  // console.log('parseint = ', parseInt(highest.substring(5), 10));
  array.map((key) => {
    var id = key.id.substring(5);
    var idnum = parseInt(id, 10);
    // console.log('id = ', parseInt(id, 10));
    // console.log('id = ', parseInt(id, 10));
    if (highest < idnum) {
      highest = idnum;
    }
    // console.log(' higher = ', highest < key.id);
  });
  return highest + 1;
}
