import React from 'react';
import Paper from '@mui/material/Paper';
// Example of Sideways () Bar chart https://codepen.io/eeyore/pen/RjzgdM
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from 'recharts';

// Hint here https://recharts.org/en-US/examples/CustomContentOfTooltip
const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    // console.log('payload is ', payload[0].payload);
    return (
      <div
        style={{
          fontSize: '1em',
          justifyContent: 'center',
        }}
      >
        <table
          style={{
            minWidth: '100px',
            margin: '10px',
            color: 'white',
          }}
        >
          <thead>
            <tr>
              {/* <th>Click to go to repo:</th> */}
              <th>{`${payload[0].payload.N}`}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> {`Total Count: ${payload[0].payload.C}  `}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

// const formatter = (_, index) => `${index + 1}`;
const formatter = (count) => `${count}`;

const handleBarClick = (value, e) => {
  // console.log('handleBarClick, metaKey = ', e.nativeEvent.metaKey);
  if (e.nativeEvent.metaKey == true) {
    window.open(`/repos/${value.activePayload[0].payload.N}`);
  } else {
    window.open(`http://www.github.com/${value.activePayload[0].payload.N}`);
  }
};

const ReChartTopSeen = (props) => {
  // need to have parent div explicitly set width
  return (
    <Paper>
      <div style={{ width: '100%', height: props.Height }}>
        <ResponsiveContainer>
          <BarChart
            data={props.RepoList}
            layout="vertical"
            cursor="pointer"
            onClick={handleBarClick}
          >
            <XAxis type="number" fill="#48A7F2" xAxisId={0} orientation="top" />
            <YAxis
              yAxisId="N"
              type="category"
              dataKey="C"
              tickCount={100}
              tickFormatter={formatter}
            />
            <Tooltip
              wrapperStyle={{ backgroundColor: '#37373D', padding: '5' }}
              labelStyle={{ color: 'green' }}
              itemStyle={{ color: 'cyan' }}
              content={<CustomTooltip />}
            />
            <Bar dataKey="C" fill="#48A7F2" yAxisId="N">
              <LabelList
                dataKey="N"
                position="insideLeft"
                fontSize={14}
                style={{ fill: 'black' }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
};

export default ReChartTopSeen;
