import * as React from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Box, Grid, Link, Paper, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { styled } from '@mui/material/styles';
import { format, parseISO } from 'date-fns';

import RepoGetCounts from '../../modules/RepoUtilities/RepoGetCounts.jsx';
import RepoDetailsSummaryData from '../../components/RepoDetails/RepoDetailsSummaryData.jsx';
import RepoTopSeen from '../../components/RepoDetails/RepoDetailsTopSeenData.jsx';

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'left',
  justifyContent: 'left',
  color: theme.palette.text.secondary,
}));
export default function RepoAccordianDetails(props) {
  let repoinfo = props.repoinfo;
  const dataurl = `https://github.com/${repoinfo.reposummary.full_name}`;
  const gitgeniusurl = `https://www.gitgenius.co/repos/${repoinfo.reposummary.full_name}`;

  let icon = null;
  if (repoinfo.reposummary.organization) {
    if (repoinfo.reposummary.organization.avatar_url) {
      icon = repoinfo.reposummary.organization.avatar_url;
    }
  }
  // console.log('RepoAccordianDetails props = ', repoinfo.reposummary);
  if (repoinfo.reposummary) {
    return (
      <div style={{ marginTop: '1em' }}>
        <AccordionDetails>
          {!repoinfo.repoindexed && !repoinfo.repoerror && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Link href={dataurl} target="_blank">
                    <h3>
                      {repoinfo.reposummary.full_name}{' '}
                      <OpenInNewIcon fontSize="small" />
                    </h3>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  {icon && (
                    <img
                      src={repoinfo.reposummary.organization.avatar_url}
                      style={{ width: 60, height: 60, float: 'left' }}
                      alt=""
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Item>
                    Description:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {repoinfo.reposummary.description}
                    </span>
                  </Item>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Item>
                    <div>
                      Created On:{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {format(
                          parseISO(repoinfo.reposummary.created_at),
                          'PPP'
                        )}
                      </span>
                    </div>
                    <div>
                      Updated On:{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {format(
                          parseISO(repoinfo.reposummary.updated_at),
                          'PPP'
                        )}
                      </span>
                    </div>
                    <div>
                      Total Stargazers:
                      <span style={{ fontWeight: 'bold' }}>
                        {repoinfo.reposummary.stargazers_count}
                      </span>
                    </div>
                    <div>
                      Total Subscriberss:{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {repoinfo.reposummary.subscribers_count}
                      </span>
                    </div>
                    <div>
                      Open Issues/Pull Requests:{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {repoinfo.reposummary.open_issues_count.toLocaleString()}
                      </span>
                    </div>
                    <div>
                      Number of forks:{' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {repoinfo.reposummary.forks_count.toLocaleString()}
                      </span>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Item style={{ textAlign: 'left' }}>
                    <RepoGetCounts reponame={repoinfo.reposummary.full_name} />
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item>
                    <Typography>
                      Not currently added to GitGenius - would you like to
                      request it? (coming soon)
                    </Typography>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          )}
          {repoinfo.repoindexed && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Link href={gitgeniusurl}>
                    <h3>
                      {repoinfo.reposummary.full_name}{' '}
                      <OpenInNewIcon fontSize="small" />
                    </h3>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  {icon && (
                    <img
                      src={repoinfo.reposummary.organization.avatar_url}
                      style={{ width: 60, height: 60, float: 'left' }}
                      alt=""
                    />
                  )}
                </Grid>
              </Grid>
              <RepoDetailsSummaryData Data={repoinfo.reposummary} />
              {/* <div className={classes.separator}></div> */}
              <RepoTopSeen Reponame={repoinfo.reposummary.full_name} />
            </Box>
          )}
        </AccordionDetails>
      </div>
    );
  }
}
