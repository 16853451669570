import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { Helmet } from 'react-helmet-async';

import {
  PRIVACY_PAGE_LOADED,
  PRIVACY_PAGE_UNLOADED,
} from '../../../state/constants/actionTypes.jsx';

import PolicyPageStyle from './PolicyPageStyle.jsx';

const mapStateToProps = (state) => ({
  showaDrawer: state.common.showaDrawer,
  useDarkMode: false,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: PRIVACY_PAGE_LOADED }),
  onUnload: () => dispatch({ type: PRIVACY_PAGE_UNLOADED }),
});

const PrivacyPolicy = ({ classes, onLoad, onUnload }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    onLoad();
    return () => {
      onUnload();
    };
  }, [onLoad, onUnload]);

  useEffect(() => {
    document.title = 'GitGenius - Privacy Policy';
  }, []);

  return (
    <main className={classes.content}>
      <Helmet>
        <title>
          GitGenius - Analyze your Repos, Followers, Stargazers, Starred Users,
          as well as your Subscribers
        </title>
        <meta
          name="description"
          content="GitGenius respects your privacy and is committed to protecting your
  Personal Information (any information that relates to an identified
  or identifiable individual)"
        />
        <link rel="canonical" href="https://www.gitgenius.co/privacy_policy" />
      </Helmet>
      <div className={classes.toolbar} />
      <div className={classes.container}>
        <h1 className="western">Privacy Policy</h1>
        <p>
          <em>Last updated: April 14th, 2024</em>
        </p>
        <h2 className="western">Overview</h2>
        <p>
          This Privacy Policy (“Privacy Policy”, “Policy”) describes how
          GitGenius (“GitGenius”, “we”, “us” or “our”) collects, uses, shares,
          processes and protects personal information (“Personal Information”)
          relating to individuals (“you”, or “your”), who may use or interact
          with our websites or services, communicate with us, contact us, or
          attend our events. “You” may be a <strong>visitor</strong> to one of
          our websites, a <strong>user</strong>
          of one or more of our Services (“User”), a{' '}
          <strong>collaborator</strong>, or a <strong>customer</strong>{' '}
          (“Customer”).
        </p>
        <p>
          GitGenius respects your privacy and is committed to protecting your
          Personal Information (any information that relates to an identified or
          identifiable individual).
        </p>
        <p>
          Note: We do <strong>not rent, sell or trade</strong> your Personal
          Information.
        </p>
        <h3 className="western">Scope</h3>
        <p>
          This Policy applies to all visitors of our websites, and users of our
          products, websites, features or services, or any other GitGenius
          websites that link to this Policy (collectively, the “Websites”),
          unless covered by a separate privacy policy, and explains how we
          collect, use, disclose, and safeguard your information. Please note
          that this Privacy Policy does not apply to the extent that we process
          Personal Information in the role of a processor (or a comparable role
          such as “service provider” in certain jurisdictions) on behalf of our
          customers, including where we offer to our customers various cloud
          products and services, through which our customers (and/or their
          affiliates) connect their own applications to our hosted platform,
          sell or offer their own products and services, send electronic
          communications to other individuals, or otherwise collect, use, share
          or process Personal Information via our cloud products and services.
        </p>
        <p>Please read this Privacy Policy carefully.</p>
        <h2 className="western">Data collections and uses</h2>
        <h3 className="western">Overview</h3>
        <p>
          This Policy describes how we collect and use your Personal
          Information, whether it is shared and/or disclosed, and how we address
          privacy matters, such as deletion of your Personal Information upon
          request, and opting-out of marketing communications. Lastly, we
          describe methods for contacting us if you have privacy questions,
          comments or feedback.
        </p>
        <h3 className="western">Personal Information we collect and receive</h3>
        <p>
          Transparency is one of the best ways to earn your trust. Below are the
          types of personal data elements we may receive and process about you
          through: the use of our website, products, or support services;
          attendance of our virtual and in-person events; third party
          advertising and marketing partners; and applications to our open
          roles. The summary sections and tables below explain in further detail
          the specific personal data elements information we collect from you or
          receive from third parties and why, based upon your relationship with
          us and as your relationship evolves with GitGenius
        </p>
        <h4 className="western">Visitors</h4>
        <p>
          When you visit our public websites, without logging into an account or
          using our products and/or services, we consider you a
          <strong>Visitor</strong>. As a Visitor, the information we collect
          from you is listed below. You’re not obligated to provide us with such
          Personal Information, and you are free to change or completely opt-out
          of information being shared with us; however refusing to provide
          requested Personal Information might prevent you from using certain
          features of the Websites.
        </p>
        <table cellPadding="3" cellSpacing="2">
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>What do we collect?</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Why do we collect it?</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Can you limit collection?</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>GitGenius Cookies</strong>
                </p>
              </td>
              <td>
                <p>
                  To recognize you when you make a return visit and deliver
                  overall a consistent experience
                </p>
              </td>
              <td>
                <p>Most modern browsers allow you to delete or limit cookies</p>
                <p>
                  You can manage your cookie preferences and settings by making
                  choices in our cookie banner or through our cookie preference
                  center
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Third-Party Tags and Cookies</strong>
                </p>
              </td>
              <td>
                <p>
                  To deliver an overall consistent experience, and measure our
                  marketing effectiveness
                </p>
              </td>
              <td>
                <p>
                  Most modern browsers allow you to delete or limit cookies,
                  including third-party cookies; however, you may not be able to
                  limit marketing tags entirely unless you do not visit our
                  sites
                </p>
                <p>
                  You can manage your cookie preferences and settings by making
                  choices in our cookie banner or through our cookie preference
                  center
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Internet Protocol (IP) Address</strong>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>Part of the basic function of the internet</p>
                  </li>
                  <li>
                    <p>To measure who is visiting us and from where</p>
                  </li>
                </ul>
              </td>
              <td>
                <p>The only way to avoid this is to not visit our sites</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Browser Metadata</strong>
                </p>
                <p>(i.e. browser type, version, operating system)</p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>Part of the basic function of the internet</p>
                  </li>
                  <li>
                    <p>
                      To ensure we maintain a positive website experience for
                      most used browsers
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <p>
                  Browsers communicate this automatically; however, some
                  third-party extensions may allow you to limit this
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Log data</strong>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>To maintain website functionality</p>
                  </li>
                </ul>
              </td>
              <td>
                <p>
                  Log data is collected automatically and may include: Internet
                  Protocol (IP) addresses; browser types, product versions, and
                  operating systems, date and time stamp, language preferences,
                  etc.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Contact Data </strong>
                </p>
                <p>(i.e., Names, emails, phone numbers, etc.)</p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      To respond to your inquiry via our “contact us” methods
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p>This information is voluntarily shared</p>
                  </li>
                  <li>
                    <p>
                      This may be required to respond to administrative or
                      account issues or inquiries.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <h4 className="western">Customers/Prospective Customers (Marketing)</h4>
        <p>
          In addition to the data above, you may also voluntarily share Personal
          Information with us, as customers or prospective customers, in order
          to receive information about products and services, or to register for
          an upcoming event.
        </p>
        <p>
          We may also receive information about you, such as your name,
          employer, title, country, state and contact information, from third
          parties, such as marketing partners, and combine it with other
          information we have about you. If we already have information about
          you in our database in accordance with applicable data retention
          periods, we would update this information. We also use data enrichment
          agencies to add information, if you did not provide certain data.
          These data enrichment agencies can also be located in third countries
          outside the European Union, European Economic Area, or UK, in
          particular in the U.S. We will then use adequate safeguards such as
          Standard Contractual Clauses, as updated from time to time, and
          supplementary measures, where required. In addition, GitGenius may
          engage third parties to deliver advertising about our Products or
          Services. This advertising helps us personalize the advertising
          content that is relevant to you. The collection and use of your data
          by third parties is subject to the applicable third party’s privacy
          policy, but the use of your data by GitGenius is subject to the
          GitGenius Privacy Policy.
        </p>
        <table cellPadding="3" cellSpacing="2">
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>What do we collect?</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Why do we collect it? </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Can you limit collection?</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Name + Email</strong>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>To respond to your inquiry</p>
                  </li>
                  <li>
                    <p>
                      Email you about product offerings, updates and other
                      marketing promotions
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      This information is voluntarily shared, to provide
                      individuals with relevant marketing materials
                    </p>
                  </li>
                  <li>
                    <p>
                      This may be required to respond to administrative or
                      account issues or inquiries.
                    </p>
                  </li>
                  <li>
                    <p>
                      You can opt out of marketing emails, see the “Contact Us”
                      section
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Company Name</strong>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>To further develop our understanding of you</p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      This information is voluntarily shared; however, for
                      marketing purposes, this is required
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Job Title</strong>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>To further develop our understanding of you</p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p>Yes, this is voluntary</p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Phone Number</strong>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>To contact you</p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p>Yes, this is voluntary</p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Analytics information</strong>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      To measure the effectiveness of our marketing activities
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <p>
                  You can manage your preferences and settings by making choices
                  in our cookie banner or through our preference center
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="western">Users/Customers</h3>
        <p>
          If you choose to register for an account with GitGenius or on our
          Websites, you will share Personal Information with us.
        </p>
        <p>
          When you register, create a User Account on our Website and begin
          using our products, we consider you a <strong>User</strong>. This
          section describes our privacy practices related to Users. Keep in mind
          Users are also considered Visitors so we collect this data in addition
          to what was described for Visitors.
        </p>
        <table cellPadding="3" cellSpacing="2">
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>What do we collect?</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Why do we collect it? </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Can you limit collection?</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Email, Username, and Password</strong>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      In order to establish your account and allow you to
                      securely access it
                    </p>
                  </li>
                  <li>
                    <p>
                      Email you about service updates, maintenance activities,
                      security notifications, weekly summaries, and other
                      account related information
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      No, these are required for us to facilitate your account
                      lifecycle or to contact you
                    </p>
                  </li>
                  <li>
                    <p>
                      You can; however, opt out of marketing emails, see the
                      “Contact Us” section
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Phone Number</strong>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>For 2-factor authentication to increase security</p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      No, this is required to enable 2-factor authentication
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>
                    Payment Data (credit card data, address, etc.)
                  </strong>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      In order to process you payment, we require the minimum
                      amount of data <strong>NOTE:</strong>{' '}
                      <em>
                        We do not store any financial data, as we use Stripe
                        and/or Shopify to process the payments.
                      </em>
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p>No, this is required to process your payment</p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Audio and video recording data</strong>
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>For sales team’s training and awareness activities.</p>
                  </li>
                  <li>
                    <p>At the request of a customer.</p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      We only enable these functions with your consent to do so.
                      At any time you may withdraw consent verbally during the
                      call or via writing prior to or following the call.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Other personal data</strong>
                </p>
              </td>
              <td>
                <p>Including but not limited to:</p>
                <ul>
                  <li>
                    <p>To facilitate use of our website contact forms.</p>
                  </li>
                  <li>
                    <p>To fulfill support tickets</p>
                  </li>
                  <li>
                    <p>To facilitate events and attendance</p>
                  </li>
                  <li>
                    <p>For interactions of social media</p>
                  </li>
                  <li>
                    <p>Participation in surveys</p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      Each of these activities are optional and personal data is
                      provided voluntarily.
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="western">Job Candidates</h3>
        <p>
          We provide the ability to submit job applications to our open job
          candidate information from recruitment agencies for recruitment
          purposes. To appropriately respond to your application, we need to
          collect and process your provided Personal Information, which may also
          be carried out electronically. If we begin an employment contract with
          you, your submitted application data will be stored for the purpose of
          processing the employment relationship in compliance with legal
          requirements. We maintain your Personal Information for the period of
          time necessary to carry out our legitimate business interests and
          according to applicable laws. For information about specific retention
          periods, please contact us at{' '}
          <a href="mailto:privacy@gitgenius.co">privacy@gitgenius.co</a>
        </p>
        <table cellPadding="3" cellSpacing="2">
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>What do we collect?</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Why do we collect it? </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Can you limit collection?</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Contact data</strong>
                </p>
                <p>(i.e., name, email, phone number, etc.)</p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      In order to contact you regarding next steps for
                      interviews, etc.
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p>No, these are required for us to process applications</p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Resume data</strong>
                </p>
                <p>(i.e., education experience, work experience, etc.)</p>
              </td>
              <td>
                <ul>
                  <li>
                    <p>
                      To process your application and determine whether you are
                      a fit for the organization.
                    </p>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    <p>No, these are required for us to process applications</p>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>How we use Personal Information</p>
        <h4 className="western">Purposes of Processing</h4>
        <p>
          In addition to those detailed above, we will not use or share your
          Personal Information in ways unrelated to those described below. We do
          not use automatic decision-making or profiling, and will not sell your
          Personal Information for any purpose.
        </p>

        <ul>
          <li>
            <p>
              <strong>Customer’s instructions.</strong> GitGenius will only
              share and disclose Personal Information in accordance with a
              Customer’s instructions, including any applicable terms in the
              Customer’s agreement(s) with us, and in compliance with applicable
              laws and regulations.
            </p>
          </li>
          <li>
            <p>
              <strong>Customer access.</strong>
              Owners, administrators and other Customer representatives and
              personnel, as defined in the Customer agreement(s) with us, may be
              able to access, modify, or restrict access to Personal
              Information.
            </p>
          </li>
          <li>
            <p>
              <strong>To provide and maintain our products.</strong> To respond
              to support requests, prevent or address any issues, monitor usage,
              and improve our products.
            </p>
          </li>
          <li>
            <p>
              <strong>Administer events.</strong>
              We will use personal data provided to facilitate an event which
              may include providing information to third party vendors and
              partners.
            </p>
          </li>
          <li>
            <p>
              <strong>To recruit candidates.</strong>
              We may use personal information candidates provide to fulfill our
              legitimate interest to recruit for open positions at GitGenius.
            </p>
          </li>
          <li>
            <p>
              *<em>Training and awareness. *</em>We may utilize different tools,
              such as call recording, which contain personal information, to
              help with the training and awareness of our employee-base. We only
              record calls with your explicit approval (which may be terminated
              or withdrawn at any time).
            </p>
          </li>
          <li>
            <p>
              *<em>During a change to GitGenius’s business. *</em>If GitGenius
              is involved in a merger, acquisition, sale of all or a portion of
              our assets, or bankruptcy, your Personal Information would be an
              asset transferred to or acquired by the successor entity or third
              party. You acknowledge that such transfers may occur and that the
              transferee may process Personal Information in a manner different
              to that set out in this Privacy Policy. You will be notified by
              email and/or a prominent notice on our Websites of any change in
              ownership or uses of your Personal Information, as well as any
              choices you may have regarding your Personal Information.
            </p>
          </li>
          <li>
            <p>
              <strong>Aggregated or de-identified information.</strong> We may
              disclose or use aggregated or de-identified Personal Information
              for any purpose. For example, we may share aggregated or
              de-identified information with prospects or partners for business
              or research purposes, such as showing a total count of active
              users accessing our products. We may use aggregated and
              de-identified Personal Information to further develop our own
              products and services as well.
            </p>
          </li>
          <li>
            <p>
              <strong>
                To enforce our rights, prevent fraud, and for safety.
              </strong>
              GitGenius may process your Personal Information to protect and
              defend the rights, property, or safety of GitGenius or third
              parties, including enforcing contracts or policies, or in
              connection with investigating and preventing fraud or security
              issues. In all such instances, use and disclosure will be limited
              as much as reasonably possible.
            </p>
          </li>
        </ul>
        <h4 className="western">Legal Basis for Processing</h4>
        <p>
          Our legal basis for collecting and using the personal information
          described in the above sections will depend on the personal
          information concerned and the specific context in which we collect it.
          However, in most cases we collect the personal information to
          facilitate the business relationships we have with our Users where we
          have consent to do so, to fulfill contractual agreements, or to pursue
          our legitimate interests where these are not overridden by the
          interests, rights, or freedoms of Users. In some cases we may be
          required legally to collect or disclose personal information; in which
          case we will make this clear. In other cases, where we rely on an
          alternative legal basis, we will do so only with the appropriate legal
          requirements in place. For any questions on our legal basis for
          processing , please contact us at{' '}
          <a href="mailto:privacy@gitgenius.co">privacy@gitgenius.co</a>.
        </p>
        <h4 className="western">Third Party Sharing</h4>
        <p>
          We may engage third party companies or individuals as service
          providers or business partners to process Personal Information and
          support our business. We may share Personal Information with third
          parties for a variety of reasons, including but not limited to:
        </p>
        <ul>
          <li>
            <p>
              Service providers, business partners, and subprocessors all help
              to support GitGenius’s products and operations. For a full list of
              GitGenius’s subprocessors that process Personal Information on our
              behalf, please contact us.
            </p>
          </li>
          <li>
            <p>
              <strong>To comply with laws.</strong> If we receive a valid
              request for information, we may disclose Personal Information if
              we reasonably believe disclosure is in accordance with or required
              to comply with the legal process, a government request, or any
              applicable law or regulation.
            </p>
          </li>
        </ul>
        <h3 className="western">How long do we keep your data?</h3>
        <p>
          We only process and keep any Personal Information for as long as
          necessary to achieve the purpose for which the information was
          originally collected. The exact length of time we keep Personal
          Information depends on our processing purposes and the statutory
          retention period for that type of information. After the statutory
          period of time passes, or if storage of Personal Information is not
          needed, Personal Information is deleted or anonymized.
        </p>
        <h3 className="western">Security</h3>
        <p>
          GitGenius takes appropriate administrative, technical, physical, and
          organizational security measures to protect your Personal Information
          from loss, theft, misuse and unauthorized access, disclosure,
          alteration, and destruction. We follow industry standards to protect
          the Personal Information submitted to us, both during transmission and
          once it is received, taking into account the nature of such
          information and the risks involved in processing, and comply with
          applicable laws and regulations. While we have taken reasonable steps
          to secure the Personal Information you provide to us, please be aware
          that despite our best efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse. For more information
          on our practices with regards to security and confidentiality please
          visit gitgenius.co/security.
        </p>
        <p>
          If you have any questions about our security, or have reason to
          believe that your interaction with us is no longer secure, please
          contact us at{' '}
          <a href="mailto:support@gitgenius.co">support@gitgenius.co</a>.
        </p>
        <h3 className="western">Policy For Children</h3>
        <p>
          We do not knowingly solicit information from or market to children
          under the age of thirteen (13). If you are under age 13, please do not
          give us any Personal Information. We encourage parents and legal
          guardians to monitor their children’s Internet usage and to help us
          enforce our Privacy Policy by instructing them to never share Personal
          Information through our Websites without their permission. If you
          suspect or become aware of any data we have collected from children
          under age 13, please contact us immediately by emailing{' '}
          <a href="mailto:privacy@gitgenius.co">privacy@gitgenius.co</a>
          or by using the contact information provided below.
        </p>
        <h3 className="western">Notice to All Non-U.S. Residents</h3>
        <p>
          Our servers are located in the U.S. If you are located outside of the
          U.S., please be aware that any information provided to us, including
          Personal Information, will be transferred from your country of origin
          to the U.S. GitGenius transfers and processes data, including the data
          transfers under the EU-U.S. Privacy Shield, the Swiss-U.S. Privacy
          Shield, the UK and EU GDPR, and in accordance with additional
          applicable laws and regulations.
        </p>
        <h3 className="western">Notice for California Residents</h3>
        <p>
          The California Consumer Privacy Act (CCPA) is a new data privacy law
          that applies to certain businesses which collect Personal Information
          from California residents. The law went into effect on January 1,
          2020. GitGenius now offers data protection terms pursuant to the EU
          GDPR and UK GDPR in Europe and the same terms under the CCPA. Your
          rights under the CCPA are described below.
        </p>
        <p>
          Please note that GitGenius <strong>does not rent or sell</strong> any
          Personal Information.
        </p>
        <p>
          In addition, California Civil Code Section 1798.83, also known as the
          “Shine The Light” law, permits California residents to request and
          obtain from us, once a year and free of charge, 1) information about
          categories of Personal Information (if any) we disclosed to third
          parties for direct marketing purposes, and 2) the names and addresses
          of the third parties with which we shared Personal Information in the
          preceding calendar year.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with our Websites, you have the right to request
          removal of unwanted data that you publicly post on our Websites. To
          request removal of such data contact us using the contact information
          provided below, and include the email address associated with your
          account and a statement that you reside in California. We will make
          sure the data is not publicly displayed on our Websites, but please be
          aware that the data may not be completely or comprehensively removed
          from our systems due to legal and regulatory requirements.
        </p>
        <p>
          If you are a California resident and would like to make a request,
          please submit your request in writing to us using the contact
          information provided below.
        </p>
        <h4 className="western">Your Rights</h4>
        <p>
          We recognize, under the EU-U.S. Privacy Shield, the Swiss-U.S. Privacy
          Shield, CCPA, the EU GDPR, the UK GDPR, the Brazilian General Data
          Protection Law (LGPD), and other applicable privacy laws, that you may
          have certain rights in regards to your Personal Information. We feel
          that your privacy and ability to preserve and exercise your rights is
          very important. You are encouraged to review and understand these
          rights as they pertain to you and your Personal Information. In
          certain circumstances, these rights include, but are not limited to:
        </p>
        <ul>
          <li>
            <p>
              <strong>Right to be Informed:</strong>
              This means we have to tell you why we process your Personal
              Information, our retention periods, and who it will be shared
              with.
            </p>
          </li>
          <li>
            <p>
              <strong>Right of Access:</strong>
              This means we have to provide you with a copy of your Personal
              Information we process upon your request.
            </p>
          </li>
          <li>
            <p>
              <strong>Right to Rectification:</strong>
              This allows you to have inaccurate Personal Information rectified,
              or completed if it is incomplete.
            </p>
          </li>
          <li>
            <p>
              <strong>Right to Erasure:</strong>
              This allows you to have your Personal Information erased.
            </p>
          </li>
          <li>
            <p>
              <strong>Right to Restrict Processing:</strong> This means you can
              limit the way we use their data.
            </p>
          </li>
          <li>
            <p>
              <strong>Right to Data Portability:</strong> This allows you to
              receive a copy of your Personal Information in a structured,
              commonly used and machine-readable format and gives you the right
              to transmit the data to another controller without hindrance.
            </p>
          </li>
          <li>
            <p>
              <strong>Right to Object:</strong>
              This allows you to object to the processing of your Personal
              Information at any time.
            </p>
          </li>
          <li>
            <p>
              <strong>Right to Non-Discrimination:</strong> The CCPA prohibits
              covered businesses from discriminating against consumers for
              exercising their CCPA rights. This means we cannot charge a
              different price, deny access to our products, or impose penalties
              for exercising your rights under the CCPA.
            </p>
          </li>
          <li>
            <p>
              <strong>Right to Withdraw Consent:</strong> This means you can
              withdraw your consent at any time.
            </p>
          </li>
        </ul>
        <p>
          In support of these rights, you may exercise any of the above rights,
          with respect to your Personal Information. You may update, correct, or
          delete your Personal Information; if you wish to delete or suspend
          your account, please note that we may retain certain information as
          required by law or for legitimate business purposes. If you have
          become aware that an account has been created about you without your
          knowledge or consent, you may contact us to request deletion of that
          said account. You may initiate the deletion request by emailing{' '}
          <a href="mailto:privacy@gitgenius.co">privacy@gitgenius.co</a>
        </p>
        <p>
          For your protection, we may only respond with the Personal Information
          associated with the particular email address that you use to send us
          your request, and we may need to verify your identity before
          implementing your request. We will respond to your request within 30
          days.
        </p>
        <h3 className="western">Changes to This Policy</h3>
        <p>
          If we make material changes to this Policy, we will revise the “Last
          Updated” date at the top of this Policy, and in some cases, where
          required by law, we may provide you with more prominent notice (such
          as adding a statement to our homepage or sending you an email
          notification). Any changes or modifications will be effective
          immediately upon posting of the updated Privacy Policy.
        </p>
        <p>
          We encourage you to review the Policy whenever you access the Websites
          to stay informed about our information practices and the ways you can
          help protect your privacy.
        </p>
        <h3 className="western">Contact Us</h3>
        <p>
          For any and all privacy-related matters, questions or comments, or to
          exercise a right under the EU’s GDPR, the UK’s GDPR or the CCPA, you
          may contact us in writing or by email. Our contact information is as
          follow:
        </p>
        <p>GitGenius</p>
        <p>
          Email: <a href="mailto:privacy@gitgenius.co">privacy@gitgenius.co</a>
        </p>
        <p>
          EU or Swiss residents with inquiries or complaints regarding this
          Privacy Policy should first contact GitGenius at{' '}
          <a href="mailto:privacy@gitgenius.co">privacy@gitgenius.co</a>. Please
          allow a reasonable amount of time to respond to your request.
        </p>
        <p>
          If these processes do not result in a resolution, you may then contact
          your local data protection authority, the U.S. Department of Commerce,
          and/or the Federal Trade Commission for assistance. Under certain
          conditions, more fully described on the Privacy Shield website
          <a href="https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint">
            https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint
          </a>
          , you may invoke binding arbitration when other dispute resolution
          procedures have been exhausted and upon written notice to GitGenius at
          <a href="mailto:privacy@gitgenius.co">privacy@gitgenius.co</a>.
        </p>
      </div>
      {/* <Footer /> */}
    </main>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(PolicyPageStyle)(PrivacyPolicy));
