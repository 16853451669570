import { Provider as ReduxProvider } from 'react-redux';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';

import App from './App.jsx';
import store from './state/configureStore.jsx';
// import { CacheProvider } from '@emotion/react';
// import createCache from '@emotion/cache';
// import { prefixer } from 'stylis';

const helmetContext = {};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
// const myCache = createCache({ key: 'cache-key' });

// const customPlugin = () => {};
// const myCache = createCache({
//   key: 'my-prefix-key',
//   stylisPlugins: [
//     customPlugin,
//     // has to be included manually when customizing `stylisPlugins` if you want
//     // to have vendor prefixes added automatically
//     prefixer,
//   ],
// });

root.render(
  <React.StrictMode>
    {/* <CacheProvider value={myCache}> */}
    <HelmetProvider context={helmetContext}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <App id="root" />
        </BrowserRouter>
      </ReduxProvider>
    </HelmetProvider>
    {/* </CacheProvider> */}
  </React.StrictMode>
);
