export const Repos = [
  {
    name: 'fireship-io/fireship.io',
  },
  {
    name: 'quay/quay',
  },
  {
    name: 'goharbor/harbor',
  },
  {
    name: 'korrel8r/korrel8r',
  },
  {
    name: 'openshift-eng/elliott',
  },
  {
    name: 'openshift-eng/aos-cd-jobs',
  },
  {
    name: 'openshift/vsphere-problem-detector',
  },
  {
    name: 'openshift/test-maven-app',
  },
  {
    name: 'openshift/service-idler',
  },
  {
    name: 'openshift/router',
  },
  {
    name: 'openshift/registry-image-widgets',
  },
  {
    name: 'openshift/php-ex',
  },
  {
    name: 'openshift/origin-web-console-smoke-test',
  },
  {
    name: 'openshift/origin-web-console',
  },
  {
    name: 'openshift/origin-web-common',
  },
  {
    name: 'openshift/origin-web-catalog',
  },
  {
    name: 'openshift/origin-branding',
  },
  {
    name: 'openshift/origin',
  },
  {
    name: 'openshift/openshift-state-metrics',
  },
  {
    name: 'openshift/openshift-origin-design',
  },
  {
    name: 'openshift/openshift-logos-icon',
  },
  {
    name: 'openshift/openshift-ansible-contrib',
  },
  {
    name: 'openshift/openshift-ansible',
  },
  {
    name: 'openshift/open-service-broker-sdk',
  },
  {
    name: 'openshift/online-hibernation',
  },
  {
    name: 'openshift/online-gluster-subvol',
  },
  {
    name: 'openshift/online-console-extensions',
  },
  {
    name: 'openshift/online-archivist',
  },
  {
    name: 'openshift/online-analytics',
  },
  {
    name: 'openshift/object-describer',
  },
  {
    name: 'openshift/node_exporter',
  },
  {
    name: 'openshift/node-problem-detector',
  },
  {
    name: 'openshift/must-gather',
  },
  {
    name: 'openshift/monitor-sample-app',
  },
  {
    name: 'openshift/monitor-project-lifecycle',
  },
  {
    name: 'openshift/microshift',
  },
  {
    name: 'openshift/library-go',
  },
  {
    name: 'openshift/kubernetes-client-go',
  },
  {
    name: 'openshift/kubernetes-apiserver',
  },
  {
    name: 'openshift/cluster-monitoring-operator',
  },
  {
    name: 'openshift/cluster-config-operator',
  },
  {
    name: 'openshift/clam-scanner',
  },
  {
    name: 'openshift/autoheal',
  },
  {
    name: 'openshift/cluster-operator',
  },
  {
    name: 'openshift/cluster-openshift-controller-manager-operator',
  },
  {
    name: 'openshift/console',
  },
  {
    name: 'openshift/console-operator',
  },
  {
    name: 'openshift/coredns',
  },
  {
    name: 'openshift/descheduler',
  },
  {
    name: 'openshift/elasticsearch-operator',
  },
  {
    name: 'openshift/eventrouter',
  },
  {
    name: 'openshift/heapster',
  },
  {
    name: 'openshift/image-inspector-client-ruby',
  },
  {
    name: 'openshift/installer',
  },
  {
    name: 'openshift/image-registry',
  },
  {
    name: 'openshift/jee-ex',
  },
  {
    name: 'openshift/jenkins-sync-plugin',
  },
  {
    name: 'openshift/jenkins-client-plugin',
  },
  {
    name: 'openshift/ansible-service-broker',
  },
  {
    name: 'kubernetes/kubernetes',
  },
];
