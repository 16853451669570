import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid2,
  Paper,
  Typography,
} from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';

import {
  COLECTIONSAI_PAGE_LOADED,
  COLECTIONSAI_PAGE_UNLOADED,
} from '../../../../state/constants/actionTypes.jsx';
import { Repos } from '../CollectionReposLIst/AI-Repos.jsx';

import RepoCard from './RepoCard.jsx';

const mapStateToProps = (state) => ({
  showaDrawer: state.common.showaDrawer,
  useDarkMode: false,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch({ type: COLECTIONSAI_PAGE_LOADED }),
  onUnload: () => dispatch({ type: COLECTIONSAI_PAGE_UNLOADED }),
});

// const TColor = '#eee'; // Text Color
const BTColor = '#48a7f2'; // Blue Text Color

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  display: 'flex',
  color: theme.palette.text.secondary,
}));

const CollectionsAI = ({ onLoad, onUnload }) => {
  var repolist = Repos;
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    onLoad();
    window.gtag('event', 'Page View', {
      event_category: 'Collections',
      event_label: 'AI ML',
    });

    return () => {
      onUnload();
    };
  }, [onLoad, onUnload]);

  /* eslint react/prop-types: 0 */
  document.title = 'GitGenius - AI Collections';

  repolist = repolist.sort((a, b) => {
    var tempa = a.name.split('/');
    var tempb = b.name.split('/');
    if (tempa[1] < tempb[1]) {
      return -1;
    }
  });

  return (
    <Container
      maxWidth="false"
      sx={{
        paddingTop: '20px',
        minHeight: '100vh',
      }}
    >
      <Helmet>
        <title>GitGenius - Collections</title>
        <meta
          name="description"
          content="GitGenius - AI Repository Collection"
        />
        <link rel="canonical" href="https://www.gitgenius.co/collections/ai" />
      </Helmet>
      <Card
        sx={{
          minWidth: 275,
          borderRadius: '15px',
          margin: '10px',
        }}
      >
        <CardContent style={{ padding: '10%', textAlign: 'center' }}>
          <Typography
            variant="h3"
            color={BTColor}
            fontWeight="bold"
            marginTop={'1em'}
            marginBottom={'1em'}
          >
            AI / ML Collection
          </Typography>
          {/* <Typography variant="h2" color={BTColor} marginBottom={'20px'}>
            AI Repository Collection
          </Typography> */}
          <Typography
            variant="h5"
            fontWeight="bold"
            textAlign={'center'}
            color={BTColor}
          >
            A selection of AI and Machine Learning repositories currently
            analyzed by GitGenius
          </Typography>
          <Typography variant="h5" textAlign={'center'} color={BTColor}>
            (in alphabetical order)
          </Typography>
        </CardContent>
      </Card>
      <Box sx={{ flexGrow: 1, alignItems: 'stretch' }}>
        <Grid2
          container
          spacing={{ xs: 1, md: 2 }}
          columns={{ xs: 1, sm: 8, md: 9, lg: 12 }}
        >
          {repolist.map((x, index) => (
            <Grid2 size={{ xs: 2, sm: 4, md: 3 }} key={index} display={'flex'}>
              <Item>
                {/* {x.name} */}
                <RepoCard repo={x.name} />
              </Item>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsAI);
