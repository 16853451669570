import {
  PROFILE_SAVED,
  PROFILE_PAGE_UNLOADED,
  PROFILE_PAGE_LOADED,
  ASYNC_START,
} from '../constants/actionTypes.jsx';

export default (state = {}, action) => {
  switch (action.type) {
    case PROFILE_PAGE_LOADED:
      // console.log('in profile reducer, PROFILE_PAGE_LOADED');

      // return {
      //   ...action.payload[0].profile,
      // };
      return {};
    case PROFILE_SAVED:
      // console.log('in profile reducer');
      // console.log('in PROFILE_SAVED action = ', action);
      // console.log('in PROFILE_SAVED state = ', state);
      return {
        ...state,
        inProgress: false,
        viewChangeCounter: state.viewChangeCounter + 1,
        // currentUser: action.error ? null : action.payload.user,
        // currentUser: action.error ? null : state.currentUser,
        // useDarkMode: action.payload.user.darkmode,
        // useDarkMode: state.currentUser.darkmode,
        errors: action.error ? action.payload.errors : null,
      };
    case PROFILE_PAGE_UNLOADED:
      return {};
    case ASYNC_START:
      return {
        ...state,
        inProgress: true,
      };
    default:
      return state;
  }
};

//  case PROFILE_SAVED:
//       console.log('in common.js, username = ', action.payload.user.username);
//       console.log('in common.js, action = ', action);
//       return {
//         ...state,
//         viewChangeCounter: state.viewChangeCounter + 1,
//         // currentUser: action.error ? null : action.payload.user,
//         currentUser: action.error ? null : state.currentUser,
//         // useDarkMode: action.payload.user.darkmode,
//         useDarkMode: state.currentUser.darkmode,
//       };
