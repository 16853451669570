export const Repos = [
  {
    name: 'aaronwangy/data-science-cheatsheet',
  },
  {
    name: 'analogjs/analog',
  },
  {
    name: 'atri-labs/atrilabs-engine',
  },
  {
    name: 'bigskysoftware/htmx',
  },
  {
    name: 'code-hike/codehike',
  },
  {
    name: 'dioxuslabs/dioxus',
  },
  {
    name: 'eddiehubcommunity/biodrop',
  },
  {
    name: 'fashionfreedom/seamly2d',
  },
  {
    name: 'fastai/nbdev',
  },
  {
    name: 'formbricks/formbricks',
  },
  {
    name: 'gyulyvgc/sniffnet',
  },
  {
    name: 'jessicategner/pypandoc',
  },
  {
    name: 'mockoon/mockoon',
  },
  {
    name: 'nuxt/nuxt',
  },
  {
    name: 'responsively-org/responsively-app',
  },
  {
    name: 'simonw/datasette',
  },
  {
    name: 'spyder-ide/spyder',
  },
  {
    name: 'termux/termux-app',
  },
  {
    name: 'trpc/trpc',
  },
];
