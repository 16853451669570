import {
  NOTIFICATIONS_SAVED,
  NOTIFICATIONS_PAGE_LOADED,
  NOTIFICATIONS_PAGE_UNLOADED,
  ASYNC_START,
} from '../constants/actionTypes.jsx';

export default (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATIONS_PAGE_LOADED:
      return {};
    case NOTIFICATIONS_SAVED:
      return {
        ...state,
        inProgress: false,
        viewChangeCounter: state.viewChangeCounter + 1,
        errors: action.error ? action.payload.errors : null,
      };
    case NOTIFICATIONS_PAGE_UNLOADED:
      return {};
    case ASYNC_START:
      return {
        ...state,
        inProgress: true,
      };
    default:
      return state;
  }
};
