import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { Formik, Form } from 'formik';
import { Container } from '@mui/system';
import { useNavigate } from 'react-router';

import ListErrors from '../ListErrors.jsx';
import agent from '../../agentAxios.jsx';
import GridContainer from '../../modules/Grid/GridContainer.jsx';
import GridItem from '../../modules/Grid/GridItem.jsx';
import Button from '../../modules/CustomButtons/Button.jsx';
import {
  NOTIFICATIONS_SAVED,
  NOTIFICATIONS_PAGE_LOADED,
  NOTIFICATIONS_PAGE_UNLOADED,
} from '../../state/constants/actionTypes.jsx';

import NotificationRepoTable from './NotificationRepoTable.jsx';
import NotificationsStyle from './NotificationsStyle.jsx';

const newRepo = (id, user, reponame, notify) => {
  return {
    id: `repo-${id}`,
    user: `${user}`,
    reponames: [`${reponame}`],
    notify: `${notify}`,
  };
};
const CheckRepoNames = (array) => {
  var len = array.length;
  array.map((d) => {
    if (!d.notify) {
      d.notify = 'none';
    }
    if (d.reponames.length > 1) {
      d.reponames.map((r) => {
        array.push(newRepo(len, d.user, r, d.notify));
        len++;
      });
      array = array.filter((item) => item.id !== d.id);
    }
  });
  return array;
};

function GetRepoData(...repos) {
  const array = CheckRepoNames(JSON.parse(repos));
  const makeDataLevel = () => {
    return array;
  };
  return makeDataLevel();
}
const mapDispatchToProps = (dispatch) => ({
  onSubmitForm: (user) => {
    dispatch({ type: NOTIFICATIONS_SAVED, payload: agent.Auth.save(user) });
  },
  onUnload: () => dispatch({ type: NOTIFICATIONS_PAGE_UNLOADED }),
  onLoad: () => dispatch({ type: NOTIFICATIONS_PAGE_LOADED }),
});

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
});
function NotificationSettings(props) {
  useEffect(() => {
    props.onLoad();
  }, []);
  document.title = 'GitGenius - Notification Settings';

  const currentUser = useSelector((state) => state.common.currentUser);
  const [repodata, setRepoData] = useState();
  let navigate = useNavigate();

  if (!currentUser) {
    return (
      <Container
        sx={{
          paddingLeft: { lg: '340px' },
          paddingTop: '80px',
          minHeight: '100vh',
        }}
      >
        Loading...
      </Container>
    );
  } else {
    if (!repodata) {
      setRepoData(GetRepoData(currentUser.Repolist));
    }
    return (
      <Container
        sx={{
          paddingLeft: { lg: '340px' },
          paddingTop: '80px',
          minHeight: '100vh',
        }}
      >
        <div className="row justify-content-center align-self-center">
          <div className="col-sm-12">
            <h1 className="text-xs-center">Notification Settings</h1>
            <ListErrors errors={props.errors} />
            <Formik
              initialValues={{}}
              onSubmit={async () => {
                var user = currentUser;
                delete user.password;
                user.Repolist = JSON.stringify(repodata);
                props.onSubmitForm(user);
                try {
                  navigate('/stargazeroverview');
                } catch (e) {
                  alert(e.message);
                }
              }}
            >
              <Form>
                <GridContainer justifyContent="center">
                  <GridItem className="form-group">
                    {repodata && (
                      <NotificationRepoTable
                        repodata={repodata}
                        setRepoData={setRepoData}
                      />
                    )}
                  </GridItem>
                  <Button
                    className="settingButton"
                    color="primary"
                    type="submit"
                    width="100%"
                  >
                    Save Repositories
                  </Button>
                </GridContainer>
              </Form>
            </Formik>
          </div>
        </div>
      </Container>
    );
  }
}

NotificationSettings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(NotificationsStyle)(NotificationSettings));
