import React from 'react';
import { styled as muistyled } from '@mui/system';

import { DragHandle } from './DragHandle.jsx';
// import styled from 'styled-components';

// const StyledStaticData = styled.td`
//   height: 3em;
// `;

const MuiStyledStaticData = muistyled('td')({
  height: '3em',
});

const MUIStyledStaticTableRow = muistyled('tr')({
  boxShadow:
    'rgb(0 0 0 / 10%) 0px 20px 25px -5px, rgb(0 0 0 / 30%) 0px 10px 10px -5px',
  outline: ' #3e1eb3 solid 1px',
});
//   box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
//     rgb(0 0 0 / 30%) 0px 10px 10px -5px;
//   outline: #3e1eb3 solid 1px;
// `;

// const StyledStaticTableRow = styled.tr`
//   box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
//     rgb(0 0 0 / 30%) 0px 10px 10px -5px;
//   outline: #3e1eb3 solid 1px;
// `;

// StaticTableRow is called when table row is moved
export const StaticTableRow = ({ row }) => {
  return (
    <MUIStyledStaticTableRow {...row.getRowProps()}>
      {row.cells.map((cell, i) => {
        if (i === 0) {
          return (
            <MuiStyledStaticData key={i} {...cell.getCellProps()}>
              <DragHandle />
              <span>{cell.render('Cell')}</span>
            </MuiStyledStaticData>
          );
        }
        return (
          <MuiStyledStaticData key={i} {...cell.getCellProps()}>
            {cell.render('Cell')}
          </MuiStyledStaticData>
        );
      })}
    </MUIStyledStaticTableRow>
  );
};
