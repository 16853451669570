export const Repos = [
  { name: 'significant-gravitas/autogpt' },
  { name: 'openai/baselines' },
  { name: 'sparticleinc/chatgpt-google-summary-extension' },
  { name: 'openai/chatgpt-retrieval-plugin' },
  { name: 'crossplane/crossplane' },
  { name: 'microsoft/deepspeed' },
  { name: 'openai/evals' },
  { name: 'nomic-ai/gpt4all' },
  { name: 'openai/gpt-discord-bot' },
  { name: 'xai-org/grok-1' },
  { name: 'openai/gym' },
  { name: 'google/jax' },
  { name: 'microsoft/jarvis' },
  { name: 'julialang/julia' },
  { name: 'keras-team/keras' },
  { name: 'langchain-ai/langchain' },
  { name: 'meta-llama/llama' },
  { name: 'getumbrel/llama-gpt' },
  { name: 'ggerganov/llama.cpp' },
  { name: 'tensorflow/mlir-hlo' },
  { name: 'tensorflow/models' },
  { name: 'openai/mujoco-py' },
  { name: 'ollama/ollama' },
  { name: 'openai/openai-cookbook' },
  { name: 'open-telemetry/opentelemetry-go' },
  { name: 'openai-translator/openai-translator' },
  { name: 'pytorch/pytorch' },
  { name: 'lightning-ai/pytorch-lightning' },
  { name: 'quivrhq/quivr' },
  { name: 'openai/spinningup' },
  { name: 'bigcode-project/starcoder2' },
  { name: 'tatsu-lab/stanford_alpaca' },
  { name: 'tensorflow/tensorflow' },
  { name: 'huggingface/transformers' },
  { name: 'triton-lang/triton' },
  { name: 'ggerganov/whisper.cpp' },
  { name: 'Sinaptik-AI/pandas-ai' },
  { name: 'OpenInterpreter/open-interpreter' },
  { name: 'huggingface/optimum-quanto' },
  { name: 'gptscript-ai/gptscript' },
  { name: 'weaviate/weaviate' },
  { name: 'VincentGranville/Large-Language-Models' },
  { name: 'mckaywrigley/chatbot-ui' },
  { name: 'run-ai/genv' },
  { name: 'QuivrHQ/quivr' },
  { name: 'run-llama/llama_index' },
  { name: 'f/awesome-chatgpt-prompts' },
  { name: 'microsoft/unilm' },
  { name: 'wecoai/aideml' },
  { name: 'apple/ml-stable-diffusion' },
  { name: 'apple/coremltools' },
  { name: 'swiftlang/llvm-project' },
  { name: 'stability-ai/generative-models' },
  { name: 'stability-ai/stablelm' },
  { name: 'stability-ai/generative-models' },
  { name: 'openinterpreter/open-interpreter' },
  { name: 'sinaptik-ai/pandas-ai' },
  { name: 'databricks/megablocks' },
  { name: 'mistralai/mistral-inference' },
  { name: 'mosaicml/llm-foundry' },
  { name: 'langgenius/dify' },
  { name: 'meta-llama/llama-recipes' },
  { name: 'meta-llama/llama-stack' },
  { name: 'meta-llama/purplellama' },
  { name: 'meta-llama/llama-models' },
  { name: 'SUSE/phoebe' },
  { name: 'anthropics/anthropic-cookbook' },
  { name: 'langchain-ai/langsmith-sdk' },
  { name: 'langchain-ai/langserve' },
  { name: 'langchain-ai/langgraph' },
  { name: 'langchain-ai/opengpts' },
];
