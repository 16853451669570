function IsRepoInList(jsonlist, repo) {
  // console.log('CheckRepoNames repos = ', array);
  var split = repo.split('/');
  var inlist = false;
  // console.log('CheckRepoNames jsonlist = ', jsonlist, len);
  // console.log('CheckRepoNames reponame = ', repo);
  // console.log('CheckRepoNames repouser = ', split);

  jsonlist.map((d) => {
    if (d.user == split[0] && d.reponames[0] == split[1]) {
      inlist = true;
      // d.reponames.map((r) => {
      //   // console.log('reponame = ', newRepo(r + len, d.user, r));
      //   array.push(newRepo(len, d.user, r));
      //   len++;
      // });
      // array = array.filter((item) => item.id !== d.id);
    }
  });
  // console.log('need break?');
  return inlist;
}

export default IsRepoInList;
