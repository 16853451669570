import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

// https://codesandbox.io/s/k36qlx0l1v?fontsize=14&file=/demo.js:1930-2018

const styles = (theme) => ({
  root: {
    // width: '90%', // default is 100%
    // width: 'max-content',
    marginTop: theme.spacing.unit * 3,
    // overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  // tableRow: {
  //   '&.MuiTableRow-root:hover': {
  //     backgroundColor: 'blue',
  //     cursor: 'pointer',
  //   },
  // },
  // tableCell: {
  //   '&$hover:hover': {
  //     color: 'pink',
  //   },
  // },
  tableHeaderFirst: {
    color: '#48A7F2',
    cursor: 'default',
    whiteSpace: 'nowrap',
  },
  tableHeaderLast: {
    color: '#FD704B',
    cursor: 'default',
    whiteSpace: 'nowrap',
  },
  tableCellFirst: {
    color: '#48A7F2',
    cursor: 'pointer',
    maxWidth: 100, // percentage also works
    // width: '10%',
    whiteSpace: 'nowrap',
    // overflow: 'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tableCellLast: {
    color: '#FD704B',
    cursor: 'pointer',
    maxWidth: 100, // percentage also works
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

function tableRowClickHandler(row) {
  // console.log('!row', row.url);
  const url = `https://github.com/${row.login}`;
  // console.log('url', url);
  window.open(url, '_blank', 'noopener,noreferrer');
}

function UserTable(props) {
  const { classes } = props;
  const textcolor = props.textcolor;
  const data = props.data;
  // if (!Array.isArray(data)) {
  //   console.log('data = ', data);
  // }
  // console.log("dataFetched", dataFe)
  if (data != null && data != 'nothing Found' && Array.isArray(data)) {
    return (
      <TableContainer
        component={Paper}
        className={classes.root}
        style={{ maxHeight: 370 }}
      >
        <Table
          sx={{ color: { textcolor } }}
          size="small"
          aria-label="simple table"
          className={classes.table}
          state={{ isLoading: true }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                className={
                  textcolor == 'first'
                    ? classes.tableHeaderFirst
                    : classes.tableHeaderLast
                }
              >
                <strong>Login</strong>
              </TableCell>
              <TableCell
                align="left"
                className={
                  textcolor == 'first'
                    ? classes.tableHeaderFirst
                    : classes.tableHeaderLast
                }
              >
                <strong>Name</strong>
              </TableCell>
              <TableCell
                align="left"
                className={
                  textcolor == 'first'
                    ? classes.tableHeaderFirst
                    : classes.tableHeaderLast
                }
              >
                <strong>Company</strong>
              </TableCell>
              <TableCell
                align="left"
                className={
                  textcolor == 'first'
                    ? classes.tableHeaderFirst
                    : classes.tableHeaderLast
                }
              >
                <strong>EMail</strong>
              </TableCell>
              <TableCell
                align="left"
                className={
                  textcolor == 'first'
                    ? classes.tableHeaderFirst
                    : classes.tableHeaderLast
                }
              >
                <strong>Created On</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                onClick={() => tableRowClickHandler(row)}
                hover
                key={row.id}
                className={classes.tableRow}
                cursor="pointer"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  align="left"
                  className={
                    textcolor == 'first'
                      ? classes.tableCellFirst
                      : classes.tableCellLast
                  }
                >
                  {row.login}
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    textcolor == 'first'
                      ? classes.tableCellFirst
                      : classes.tableCellLast
                  }
                >
                  {row.name}
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    textcolor == 'first'
                      ? classes.tableCellFirst
                      : classes.tableCellLast
                  }
                >
                  {row.company}
                </TableCell>
                <TableCell
                  align="left"
                  className={
                    textcolor == 'first'
                      ? classes.tableCellFirst
                      : classes.tableCellLast
                  }
                >
                  {row.email}
                </TableCell>
                <TableCell
                  align="left"
                  width="10px"
                  className={
                    textcolor == 'first'
                      ? classes.tableCellFirst
                      : classes.tableCellLast
                  }
                >
                  {row.created_at.substring(0, 10)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

UserTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(UserTable);
