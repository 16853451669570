import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export function SelectHowOften(props) {
  var value = '';
  if (props.repodata) {
    value = props.repodata[props.index].notify;
  }
  const handleChange = (event) => {
    // console.log('handleChange = ', event);
    var newrepodata = props.repodata;
    newrepodata[props.index].notify = event.target.value;
    props.setRepoData(newrepodata);
  };

  return (
    <FormControl sx={{ m: 0, minWidth: 150 }} size="small" margin="dense">
      <InputLabel id="demo-select-small" sx={{ fontSize: '14px' }}>
        How Often
      </InputLabel>
      <Select
        sx={{ fontSize: '14px' }}
        labelId="demo-select-small"
        id="demo-select-small"
        value={value}
        label="How Often (at most)"
        onChange={handleChange}
      >
        <MenuItem value={'none'} sx={{ fontSize: '14px' }}>
          <em>None</em>
        </MenuItem>
        <MenuItem value={'d'} sx={{ fontSize: '14px' }}>
          Once A Day
        </MenuItem>
        <MenuItem value={'w'} sx={{ fontSize: '14px' }}>
          Once A Week
        </MenuItem>
      </Select>
    </FormControl>
  );
}
