export const Repos = [
  { name: 'cockroachdb/cockroach' },
  { name: 'influxdata/influxdb' },
  { name: 'supabase/supabase' },
  { name: 'redis/redis' },
  { name: 'etcd-io/etcd' },
  { name: 'meilisearch/meilisearch' },
  { name: 'dbeaver/dbeaver' },
  { name: 'prisma/prisma' },
  { name: 'metabase/metabase' },
  { name: 'pingcap/tidb' },
  { name: 'QuivrHQ/quivr' },
  { name: 'facebook/rocksdb' },
  { name: 'surrealdb/surrealdb' },
  { name: 'mongodb/mongo' },
  { name: 'dragonflydb/dragonfly' },
  { name: 'taosdata/TDengine' },
  { name: 'typicode/lowdb' },
  { name: 'timescale/timescaledb' },
  { name: 'apache/zookeeper' },
  { name: 'apple/foundationdb' },
  { name: 'cockroachdb/pebble' },
];
