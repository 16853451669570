import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { Formik, Form } from 'formik';
import { Alert, CircularProgress } from '@mui/material';
import axios from 'axios';
import { Container } from '@mui/system';

import ListErrors from '../ListErrors.jsx';
import agent from '../../agentAxios.jsx';
import GridContainer from '../../modules/Grid/GridContainer.jsx';
import GridItem from '../../modules/Grid/GridItem.jsx';
import Button from '../../modules/CustomButtons/Button.jsx';
import withRouter from '../../routes/withRouter.jsx';
import APIServerRoot from '../../modules/UIComponents/ApiEnvironment.jsx';
import {
  REPOSETTINGS_SAVED,
  REPOSETTINGS_PAGE_UNLOADED,
  REPOSETTINGS_PAGE_LOADED,
} from '../../state/constants/actionTypes.jsx';

import AddRepoDialog from './AddRepoDialog.jsx';
import AddBasicRepoDialog from './AddBasicRepoDialog.jsx';
import { RepoTable } from './DndKit-TableFunctions/RepoTable.jsx';
import GetRepoData from './HelperFunctions/GetRepoData';
import RepoLibrarySection from './RepoLibrarySection.jsx';
import reposettingsStyle from './reposettingsStyle.jsx';

const mapDispatchToProps = (dispatch) => ({
  onSubmitForm: (user) => {
    dispatch({ type: REPOSETTINGS_SAVED, payload: agent.Auth.save(user) });
  },
  onUnload: () => dispatch({ type: REPOSETTINGS_PAGE_UNLOADED }),
  onLoad: () => dispatch({ type: REPOSETTINGS_PAGE_LOADED }),
});

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
});
function RepoSettings(props) {
  const currentUser = useSelector((state) => state.common.currentUser);
  const [repodata, setRepoData] = useState();
  const [saved, setSaved] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [libraryList, setLibraryRepoList] = useState([]);

  useEffect(() => {
    props.onLoad();
    window.scrollTo(0, 0);
    setLoading(false); //stop loading when data is fetched

    let abortController = new AbortController();
    var API_DETAILS = `${APIServerRoot()}/search/getrepoallnames`;
    const doFetch = async () => {
      const axiosresponse = await axios({
        method: 'POST',
        url: API_DETAILS,
      });
      setLoading(false); //stop loading when data is fetched
      setLibraryRepoList(axiosresponse.data);
      // console.log('setLibraryRepoList = ', axiosresponse.data);
    };
    doFetch();
    return () => {
      // console.log('abort controller');
      abortController.abort();
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Current Repository List',
        columns: [
          {
            Header: 'Repo Owner',
            accessor: 'user',
          },
          {
            Header: 'Repo Name',
            accessor: 'reponames',
          },
        ],
      },
    ],
    []
  );

  if (isLoading) {
    return (
      <Container
        sx={{
          paddingLeft: { lg: '340px' },
          paddingTop: '80px',
          minHeight: '100vh',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <p>Loading Repository Library...</p>
          <CircularProgress />
        </div>
      </Container>
    );
  } else if (!currentUser) {
    return (
      <Container
        sx={{
          paddingLeft: { lg: '340px' },
          paddingTop: '80px',
          minHeight: '100vh',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <p>Loading...</p>
          <CircularProgress />
        </div>
      </Container>
    );
  } else {
    if (!repodata) {
      setRepoData(GetRepoData(currentUser.Repolist));
    }
    var dialog;
    if (currentUser.member_status == 'paid') {
      dialog = <AddRepoDialog data={repodata} setData={setRepoData} />;
    } else {
      dialog = <AddBasicRepoDialog data={repodata} setData={setRepoData} />;
    }
    return (
      <Container
        sx={{
          paddingLeft: { lg: '340px' },
          paddingTop: '80px',
          minHeight: '100vh',
        }}
      >
        <div className="row justify-content-center align-self-center">
          <div className="col-sm-12">
            <h1 className="text-xs-center">Repository Settings</h1>
            <ListErrors errors={props.errors} />
            {dialog}
            <RepoLibrarySection
              data={repodata}
              setData={setRepoData}
              libraryList={libraryList}
            />
            <Formik
              initialValues={{}}
              onSubmit={async () => {
                var user = currentUser;
                delete user.password;
                user.Repolist = JSON.stringify(repodata);
                props.onSubmitForm(user);
                // navigate(0);
                setSaved(true);
                setTimeout(() => {
                  setSaved(false);
                }, 3000);
                // alert('Repositories Saved');
              }}
            >
              <Form>
                <GridContainer justifyContent="center">
                  <GridItem className="form-group">
                    {repodata && (
                      <RepoTable
                        columns={columns}
                        data={repodata}
                        setData={setRepoData}
                        Darkmode={currentUser.darkmode}
                      />
                    )}
                  </GridItem>
                  <Button
                    className="settingButton"
                    color="primary"
                    type="submit"
                    width="100%"
                  >
                    Save Repositories
                  </Button>
                  <GridItem>
                    {saved && (
                      <Alert severity="success">Repositories Saved</Alert>
                    )}
                  </GridItem>
                </GridContainer>
              </Form>
            </Formik>
          </div>
        </div>
      </Container>
    );
  }
}

RepoSettings.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(reposettingsStyle)(RepoSettings))
);
